import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { ChartData, ChartDataset, ChartOptions, TooltipItem } from 'chart.js';
import { ChartModule } from 'primeng/chart';
import { MultiSelectModule } from 'primeng/multiselect';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';

import { DashboardColorOptions, GenderColorOptions, LeadStatisticOptions } from '@core/constants/dashboard';
import { LEAD_STATISTIC } from '@core/enum/dashboard';
import { COLOR } from '@core/enum/color';
import { ChartOption, LeadStageChartData } from '@core/models/interfaces/dashboard';
import { DashboardService } from '@core/services/dashboard.service';
import { DoughnutChartLegendComponent } from '@feature/dashboard/components/doughnut-chart-legend/doughnut-chart-legend.component';
import { DashboardStatisticComponent } from '@shared/components/dashboard-statistic/dashboard-statistic.component';

@Component({
  selector: 'app-lead-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    ChartModule,
    MultiSelectModule,
    TranslateModule,
    DashboardStatisticComponent,
    DoughnutChartLegendComponent
  ],
  templateUrl: './lead-dashboard.component.html',
  styleUrl: './lead-dashboard.component.scss'
})
export class LeadDashboardComponent implements OnChanges, OnDestroy {
  @Input() userId?: string = '';
  @Input() userGroupId?: string = '';
  @Input() rangeDates: Date[] = [];

  readonly unsubscribe$ = new Subject();
  readonly translatePrefix = 'dashboard.';
  readonly doughnutChartOptions = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: TooltipItem<'doughnut'>) {
            const total = tooltipItem?.dataset?.data?.reduce((a: number, b: number) => a + b, 0) || 0;
            return `${tooltipItem?.formattedValue} (${(((tooltipItem?.parsed || 0) / total) * 100).toFixed(2)}%)`;
          }
        }
      }
    }
  };
  readonly lineChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#758195'
        }
      },
      y: {
        ticks: {
          stepSize: 20,
          color: '#758195'
        }
      }
    }
  };
  readonly barChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: COLOR.GRAY
        }
      },
      y: {
        ticks: {
          color: COLOR.GRAY,
          maxTicksLimit: 7
        }
      }
    }
  };

  leadStatistics = LeadStatisticOptions;

  leadsByStatus: ChartOption[] = [];
  leadsBySource: ChartOption[] = [];
  leadType: ChartOption[] = [];
  leadsByStage: ChartOption[] = [];
  leadsByGender: ChartOption[] = [];

  leadsByStatusChartData: ChartData;
  leadsBySourceChartData: ChartData;
  leadTypeChartData: ChartData;
  leadTagChartData: ChartData;
  leadsByStatesChartData: ChartData;
  leadsByAgeAndGenderChartData: ChartData;
  leadsByGenderChartData: ChartData;
  leadsByStageChartData: LeadStageChartData[] = [];
  leadsByOwnerChartData: ChartData;

  constructor(private dashboardService: DashboardService) {}

  ngOnChanges(): void {
    this.fetchStatisticData();
    this.fetchChartData();
  }

  fetchStatisticData() {
    const dateFrom = this.rangeDates?.[0]
      ? moment(this.rangeDates?.[0]).startOf('date').toDate().toISOString()
      : undefined;
    const dateTo = this.rangeDates?.[1] ? moment(this.rangeDates?.[1]).endOf('date').toDate().toISOString() : undefined;
    this.dashboardService
      .getLeadStatisticalData({
        userId: this.userId,
        groupId: this.userGroupId,
        from: dateFrom,
        to: dateTo
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          const data = res.data;
          for (let option of this.leadStatistics) {
            switch (option.id) {
              case LEAD_STATISTIC.TOTAL_LEADS_ALL_TIME: {
                option.rate = data.totalAllTime?.rate;
                option.amount = data.totalAllTime?.amount;
                break;
              }
              case LEAD_STATISTIC.CREATED_LEADS_ALL_TIME: {
                option.rate = data.createdAllTime?.rate;
                option.amount = data.createdAllTime?.amount;
                break;
              }
              case LEAD_STATISTIC.ASSIGNED_LEADS_ALL_TIME: {
                option.rate = data.assignedAllTime?.rate;
                option.amount = data.assignedAllTime?.amount;
                break;
              }
              case LEAD_STATISTIC.TOTAL_LEADS_BY_TIME: {
                option.rate = data.totalByTime?.rate;
                option.amount = data.totalByTime?.amount;
                break;
              }
              case LEAD_STATISTIC.CREATED_LEADS_BY_TIME: {
                option.rate = data.createdByTime?.rate;
                option.amount = data.createdByTime?.amount;
                break;
              }
              case LEAD_STATISTIC.ASSIGNED_LEADS_BY_TIME: {
                option.rate = data.assignedByTime?.rate;
                option.amount = data.assignedByTime?.amount;
                break;
              }
            }
          }
        }
      });
  }

  fetchChartData() {
    const dateFrom = this.rangeDates?.[0]
      ? moment(this.rangeDates?.[0]).startOf('date').toDate().toISOString()
      : undefined;
    const dateTo = this.rangeDates?.[1] ? moment(this.rangeDates?.[1]).endOf('date').toDate().toISOString() : undefined;

    this.dashboardService
      .getLeadChartData({
        userId: this.userId,
        groupId: this.userGroupId,
        from: dateFrom,
        to: dateTo
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          const statusChartData = res.data.status;
          const sourceChartData = res.data.source;
          const typeChartData = res.data.type;
          const tagChartData = res.data.tag;
          const stateChartData = res.data.states;
          const ageAndGenderChartData = res.data.ageGender;
          const genderChartData = res.data.gender;
          const stageChartData = res.data.stage.sort((a, b) => b.amount - a.amount);
          const ownerChartData = res.data.owner;

          // LEAD STATUS
          if (statusChartData) {
            this.leadsByStatus = statusChartData
              ?.map((item, index) => {
                const color = item?.option?.color || DashboardColorOptions[index % 10].normal;
                return {
                  label: item?.option?.name,
                  data: item.amount,
                  color: color
                };
              })
              ?.sort((a, b) => b?.data - a?.data);
            this.leadsByStatusChartData = {
              labels: this.leadsByStatus.map(item => item.label),
              datasets: [
                {
                  data: this.leadsByStatus.map(item => item.data),
                  backgroundColor: this.leadsByStatus.map(item => item.color)
                }
              ]
            };
          }

          // LEAD SOURCE
          if (sourceChartData) {
            this.leadsBySource = sourceChartData
              ?.sort((a, b) => b.amount - a.amount)
              ?.map((item, index) => {
                const color = DashboardColorOptions[index % 10].normal;
                return {
                  label: item?.option?.name,
                  data: item.amount,
                  color: color
                };
              });
            this.leadsBySourceChartData = {
              labels: this.leadsBySource.map(item => item.label),
              datasets: [
                {
                  data: this.leadsBySource.map(item => item.data),
                  backgroundColor: this.leadsBySource.map(item => item.color)
                }
              ]
            };
          }

          // LEAD TYPE
          if (typeChartData) {
            this.leadType = typeChartData
              ?.sort((a, b) => b.amount - a.amount)
              ?.map((item, index) => {
                const color = DashboardColorOptions[index % 10].normal;
                return {
                  label: item?.option?.name,
                  data: item.amount,
                  color: color
                };
              });

            this.leadTypeChartData = {
              labels: this.leadType.map(item => item.label),
              datasets: [
                {
                  data: this.leadType.map(item => item.data),
                  backgroundColor: this.leadType.map(item => item.color)
                }
              ]
            };
          }

          // LEAD TAG
          if (tagChartData) {
            const labels = tagChartData.map(item => item.option.name);
            const data = tagChartData.map(item => item.amount);
            this.leadTagChartData = {
              labels,
              datasets: [
                {
                  data,
                  backgroundColor: COLOR.BLUE,
                  maxBarThickness: 32,
                  borderRadius: 2
                }
              ]
            };
          }

          // STATES
          if (stateChartData) {
            const labels = stateChartData.map(item => item.option.name);
            const data = stateChartData.map(item => item.amount);
            this.leadsByStatesChartData = {
              labels,
              datasets: [
                {
                  data,
                  backgroundColor: '#3B82F6',
                  maxBarThickness: 32,
                  borderRadius: 2
                }
              ]
            };
          }

          // AGE & GENDER
          if (ageAndGenderChartData) {
            const datasets: ChartDataset[] = [
              {
                label: 'Male',
                data: [],
                backgroundColor: COLOR.BLUE,
                maxBarThickness: 16,
                borderRadius: 2
              },
              {
                label: 'Female',
                data: [],
                backgroundColor: COLOR.PINK,
                maxBarThickness: 16,
                borderRadius: 2
              },
              {
                label: 'Other',
                data: [],
                backgroundColor: COLOR.PURPLE,
                maxBarThickness: 16,
                borderRadius: 2
              },
              {
                label: 'Unknown',
                data: [],
                backgroundColor: COLOR.GRAY,
                maxBarThickness: 16,
                borderRadius: 2
              }
            ];

            const labels = ageAndGenderChartData.map(item => item.name);
            const genders = ageAndGenderChartData.map(item => item.options);

            datasets[0].data = genders.flatMap(gender => {
              return gender.filter(item => item.option.code === 'MALE').map(item => item.amount);
            });
            datasets[1].data = genders.flatMap(gender => {
              return gender.filter(item => item.option.code === 'FEMALE').map(item => item.amount);
            });
            datasets[2].data = genders.flatMap(gender => {
              return gender.filter(item => item.option.code === 'OTHER').map(item => item.amount);
            });
            datasets[3].data = genders.flatMap(gender => {
              return gender.filter(item => item.option.code === 'UNKNOWN').map(item => item.amount);
            });

            this.leadsByAgeAndGenderChartData = {
              labels,
              datasets
            };
            
          }

          // GENDER
          if (genderChartData) {
            this.leadsByGender = genderChartData
              ?.map((item, index) => {
                const color = GenderColorOptions[index % 10];
                return {
                  label: item?.option?.name,
                  data: item.amount,
                  color: color
                };
              })
              ?.sort((a, b) => b?.data - a?.data);

            this.leadsByGenderChartData = {
              labels: this.leadsByGender.map(item => item.label),
              datasets: [
                {
                  data: this.leadsByGender.map(item => item.data),
                  backgroundColor: this.leadsByGender.map(item => item.color)
                }
              ]
            };
          }

          //STAGE
          if (stageChartData) {
            this.leadsByStage = stageChartData.map((item, index) => {
              const bgColor = this.getBgColorStage(index);
              return {
                label: item.option.name,
                color: bgColor,
                data: item.amount
              };
            });
            const totalAmount = stageChartData.reduce((a, b) => a + b.amount, 0);
            this.leadsByStageChartData = stageChartData.map((item, index) => {
              const polygon = this.getPolygonStage(index);
              const bgColor = this.getBgColorStage(index);

              return {
                label: item.option.name,
                data: item.amount,
                polygon,
                percent: (item.amount / totalAmount) * 100 || 0,
                bgColor
              };
            });
          }

          if (ownerChartData) {
            const labels = ownerChartData.map(item => item.periodName);
            const data = ownerChartData.map(item => item.total);
            const average = ownerChartData.reduce((a, b) => a + b.total, 0) / data.length;

            this.leadsByOwnerChartData = {
              labels,
              datasets: [
                {
                  data: data,
                  fill: false,
                  borderColor: '#3B82F6',
                  tension: 0,
                  borderWidth: 2
                },
                {
                  data: Array(data.length).fill(average),
                  fill: false,
                  borderColor: '#EA580C',
                  tension: 0,
                  pointRadius: 0,
                  borderWidth: 1
                }
              ]
            };
          }
        }
      });
  }

  getPolygonStage(index: number) {
    if (index > 3) {
      return 'polygon(27% 0%, 73% 0%, 73% 100%, 27% 100%)';
    }
    const topLeft = index * 7;
    const topRight = 100 - index * 7;
    const bottomRight = 94 - index * 7;
    const bottomLeft = 6 + index * 7;

    return `polygon(${topLeft}% 0%, ${topRight}% 0%, ${bottomRight}% 100%, ${bottomLeft}% 100%)`;
  }

  getBgColorStage(index: number) {
    const BLUE_COLORS = ['#93C5FD', '#60A5FA', '#3B82F6', '#2563EB', '#1D4ED8'];
    return index >= 4 ? BLUE_COLORS[BLUE_COLORS.length] : BLUE_COLORS[index % 5];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
