<div class="flex flex-col gap-5 px-8 py-5">
  <span class="text-5 font-semibold">Partner apps</span>

  <div class="flex gap-5">
    <!-- LEFT-->
    <app-partner-apps-view
      [partnerApps]="partnerApps"
      [(partnerAppName)]="partnerAppName"
      [(partnerAppActive)]="partnerAppActive"
      [(messageFlowActive)]="messageFlowActive"
      (partnerAppNameChange)="onCreatePartnerApps($event)"
      (messageFlowActiveChange)="onShowMessageFlow($event)"
      (onDeleteMessageFlowChange)="onDeleteMessageFlow($event)"
      (createMessageFlow)="onCreateMessageFlow($event)"
      (deletePartnerApps)="onDeletePartnerApps($event)"
      (partnerAppActiveChange)="onPartnerAppActiveChange()"
      (searchPartnerApps)="onSearchPartnerApp($event)"></app-partner-apps-view>

    <!-- RIGHT-->
    <div class="w-full">
      <app-message-flow-view
        [isShowNotMessageFlow]="isShowNotMessageFlow"
        [isShowMessageFlow]="isShowMessageFlow"
        [messageFlowName]="messageFlowName"
        [messageFlowActive]="messageFlowActive"
        [currentPartnerAppActive]="currentPartnerAppActive"
        (createMessageFlow)="onCreateMessageFlow($event)"
        (messageFlowUpdate)="onUpdateMessageFlow($event)"></app-message-flow-view>
    </div>
  </div>
</div>
