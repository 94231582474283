<div class="py-6">
  <h2 class="font-medium text-neutral-600 mb-4">REACH & ENGAGEMENT PERFORMANCE</h2>

  <div class="flex gap-6">
    @for (statistic of statistics; track $index) {
      <div class="flex-1">
        <app-dashboard-statistic
          [title]="statistic.title"
          [amount]="statistic.amount"
          [rate]="statistic.rate"
          [dataType]="statistic.dataType"></app-dashboard-statistic>
      </div>
    }
  </div>

  <div class="mt-6 grid grid-cols-3 gap-6">
    <!-- Impressions -->
    <app-bar-line-chart
      title="Impressions"
      [totalAmount]="34268"
      [dataType]="UNIT_NUMBER.NUMBER"
      [data]="impressions"></app-bar-line-chart>

    <!-- Clicks -->
    <app-bar-line-chart
      title="Clicks"
      [totalAmount]="12762"
      [dataType]="UNIT_NUMBER.NUMBER"
      [data]="clicks"></app-bar-line-chart>

    <!-- CTR -->
    <app-bar-line-chart
      title="CTR "
      [totalAmount]="11.25"
      [dataType]="UNIT_NUMBER.PERCENT"
      [data]="CTR"></app-bar-line-chart>
  </div>
</div>
