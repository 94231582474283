import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { COLOR } from '@core/enum/color';
import { ChartOption } from '@core/models/interfaces/dashboard';
import { DoughnutChartLegendComponent } from '@feature/dashboard/components/doughnut-chart-legend/doughnut-chart-legend.component';
import { ChartData, ChartOptions } from 'chart.js';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { HeatMapComponent } from '../heatmap/heatmap.component';
import { WeekDataDTO } from '@core/models/interfaces/marketing-dashboard/dashboard';
import { CustomCalendarComponent } from '@shared/components/custom-calendar/custom-calendar.component';
import moment from 'moment';

@Component({
  selector: 'app-audience-and-insights',
  standalone: true,
  imports: [
    ChartModule,
    DoughnutChartLegendComponent,
    DropdownModule,
    CommonModule,
    FormsModule,
    HeatMapComponent,
    CustomCalendarComponent
  ],
  templateUrl: './audience-and-insights.component.html',
  styleUrl: './audience-and-insights.component.scss'
})
export class AudienceAndInsightsComponent {
  readonly barChartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    // aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [5, 5]
        },
        ticks: {
          color: COLOR.GRAY
        },
        // barPercentage: 6.0, 
        // categoryPercentage: 0.4
      },
      y: {
        ticks: {
          callback: function (value: number) {
            return `$${value}.00`;
          },
          stepSize: 20
        },

        color: COLOR.GRAY,
        maxTicksLimit: 7
      }
    }
  };

  ageAndGenderChartData: ChartData = {
    labels: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
    datasets: [
      {
        label: 'Male',
        data: [20, 10, 60, 15, 25, 11],
        backgroundColor: '#3B82F6',
        maxBarThickness: 16,
        borderRadius: 2
      },
      {
        label: 'Female',
        data: [18, 17, 30, 5, 15, 11],
        backgroundColor: '#EC4899',
        maxBarThickness: 16,
        borderRadius: 2
      }
    ]
  };

  topStatesChartData: ChartData = {
    labels: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL'],
    datasets: [
      {
        data: [20, 10, 40, 85, 25, 11, 20, 10, 40, 58],
        backgroundColor: '#3B82F6',
        maxBarThickness: 16,
        borderRadius: 2
      }
    ]
  };

  placementsChartData: ChartData = {
    labels: ['FB Reels', 'Feed', 'FB Stories', 'In-stream video', 'Ads on Reels', 'Others'],
    datasets: [
      {
        data: [20, 10, 40, 60, 25, 11, 20],
        backgroundColor: '#06B6D4',
        maxBarThickness: 16,
        borderRadius: 2
      }
    ]
  };

  leadsByGender: ChartOption[] = [
    {
      label: 'MALE',
      data: 1,
      color: '#3B82F6'
    },
    {
      label: 'FEMALE',
      data: 0,
      color: '#EC4899'
    }
  ];

  topStateDropdown = [
    { name: 'Amount spent', code: 'Amount spent' },
    { name: 'Conversion leads', code: 'Conversion leads' },
    { name: 'Average cost: CPC & CPL', code: 'Average cost: CPC & CPL' },
    { name: 'Impressions', code: 'Impressions' }
  ];
  generalDropdown = [
    { name: 'Amount spent', code: 'Amount spent' },
    { name: 'Conversion', code: 'Conversion' },
    { name: 'Message conversations started', code: 'Message conversations started' },
    { name: 'Cost per lead', code: 'Cost per lead' },
    { name: 'Total revenue', code: 'Total revenue' },
    { name: 'Impressions', code: 'Impressions' },
    { name: 'Clicks (all)', code: 'Clicks (all)' },
    { name: 'CTR', code: 'CTR' },
    { name: 'Average CPC', code: 'Average CPC' },
    { name: 'Cost per engagement', code: 'Cost per engagement' }
  ];
  selectedDataTypeAgeAndGender: string = 'Amount spent';
  selectedDataTypeDayAndTime: string = 'Amount spent';
  selectedDataTypePlacements: string = 'Amount spent';
  selectedDataTypeTopState: string = 'Amount spent';
  datesSelected: Array<Date | undefined> = [];

  get timeRange() {
    if (this.datesSelected?.[0] && this.datesSelected?.[1]) {
      const since = moment(this.datesSelected?.[0]).format('YYYY-MM-DD');
      const until = moment(this.datesSelected?.[1]).format('YYYY-MM-DD');
      return {
        since,
        until
      };
    } else {
      return undefined;
    }
  }

  heatmap: WeekDataDTO[] = [
    {
      day: 'Sun',
      value: [1, 3, 2, 4, 5, 1, 3, 4, 2, 5, 1, 2, 4, 3, 5, 1, 2, 3, 4, 5, 1, 3, 4, 2]
    },
    {
      day: 'Sat',
      value: [5, 4, 3, 2, 1, 5, 4, 3, 2, 1, 5, 3, 4, 2, 1, 5, 4, 3, 2, 1, 5, 4, 3, 2]
    },
    {
      day: 'Fri',
      value: [3, 2, 1, 5, 4, 3, 2, 1, 5, 4, 3, 1, 2, 5, 4, 3, 2, 1, 5, 4, 3, 2, 1, 5]
    },
    {
      day: 'Thu',
      value: [4, 5, 1, 3, 2, 4, 5, 1, 3, 2, 4, 2, 5, 1, 3, 4, 2, 1, 5, 3, 4, 2, 1, 5]
    },
    {
      day: 'Wed',
      value: [2, 1, 5, 3, 4, 2, 1, 5, 3, 4, 2, 3, 1, 5, 4, 3, 2, 1, 5, 4, 3, 2, 1, 5]
    },
    {
      day: 'Tue',
      value: [1, 2, 4, 3, 5, 1, 2, 4, 3, 5, 1, 4, 3, 5, 2, 1, 4, 3, 5, 2, 1, 3, 4, 5]
    },
    {
      day: 'Mon',
      value: [5, 3, 4, 2, 1, 5, 3, 4, 2, 1, 5, 2, 4, 1, 3, 5, 2, 4, 1, 3, 5, 4, 3, 2]
    }
  ];

  onChangeDataType() {
    // console.log('Selected data type:', this.selectedDataType);
  }
}
