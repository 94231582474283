export const MessageTemplateSample =
  '[{"receiving_app_id":556376998159104,"message":{"attachment":{"type":"template","payload":{"buttons":[{"payload":"{\\"tp\\":1,\\"id\\":\\"BotcakeAI.Payload\\",\\"flid\\":354192607,\\"ck\\":\\"fsw8rcaire\\",\\"btnt\\":\\"blocks\\",\\"btk\\":\\"fn0bk2dj4k\\",\\"bk\\":\\"v47q2jk6it\\",\\"bid\\":2269990}","title":"Tìm hiểu Kaizen👈","type":"postback"}],"template_type":"button","text":"Anh/chị bấm vào nút tìm hiểu thêm bên dưới để nhận thông tin chi tiết về Kai-Zen® nhé:\\n"}}}}]';

export const DefaultText = 'Hi! Please let us know how we can help you';

export const DefaultButton = [
  {
    title: 'Show me the product!'
  },
  {
    title: 'Tell me more'
  }
];
