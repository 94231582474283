import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UNIT_NUMBER } from '@core/enum/dashboard';
import { ChartData, ChartOptions } from 'chart.js';
import { ChartModule } from 'primeng/chart';
import { SelectButtonModule } from 'primeng/selectbutton';

@Component({
  selector: 'app-bar-line-chart',
  standalone: true,
  imports: [CommonModule, ChartModule, SelectButtonModule, FormsModule],
  templateUrl: './bar-line-chart.component.html',
  styleUrl: './bar-line-chart.component.scss'
})
export class BarLineChartComponent implements OnInit {
  @Input() data: ChartData;
  @Input() title: string = '';
  @Input() totalAmount: number = 0;
  @Input() dataType: UNIT_NUMBER = UNIT_NUMBER.NUMBER;

  readonly UNIT_NUMBER = UNIT_NUMBER;
  readonly tabOptions = [
    {
      title: 'Line',
      value: 'LINE'
    },
    {
      title: 'Bar',
      value: 'BAR'
    }
  ];

  chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 5,
          callback: value => value
        },
        grid: {
          drawTicks: false,
          lineWidth: 0.3
        },
        border: {
          display: false
        }
      }
    },
    datasets: {
      bar: {
        barPercentage: 0.5,
        categoryPercentage: 0.8,
        maxBarThickness: 20
      }
    }
  };

  tabActive: 'BAR' | 'LINE' = 'BAR';

  ngOnInit(): void {
    if (this.dataType === UNIT_NUMBER.PERCENT) {
      this.chartOptions = {
        ...this.chartOptions,
        scales: {
          ...this.chartOptions.scales,
          y: {
            ...this.chartOptions.scales?.['y'],
            ticks: {
              maxTicksLimit: 5,
              callback: value => value + '%'
            }
          }
        }
      };
    }
    if (this.dataType === UNIT_NUMBER.CURRENCY) {
      this.chartOptions = {
        ...this.chartOptions,
        scales: {
          ...this.chartOptions.scales,
          y: {
            ...this.chartOptions.scales?.['y'],
            ticks: {
              maxTicksLimit: 5,
              callback: value => '$' + value + '.00'
            }
          }
        }
      };
    }
  }
}
