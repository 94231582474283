import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, exhaustMap, forkJoin, from, map, of, switchMap, tap } from 'rxjs';

import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { flattenPermissionCodes } from '@shared/utils/flattenPermissionCodes.util';
import {
  login,
  loginFailed,
  loginSuccess,
  otpRequired,
  setFacebookAccounts,
  verifyOtp,
  verifyOtpFailed,
  verifyOtpSuccess
} from './auth.actions';
import { AuthInfo } from '@core/models/interfaces/auth';
import { FacebookAdsAdminService } from '@core/services/facebook.service.ts/facebook-ads-admin.service';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { setFacebookConfig } from '@state/system/system.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private action$: Actions,
    private router: Router,
    private authService: AuthService,
    private ngxPermissionsService: NgxPermissionsService,
    private facebookAdsAdminService: FacebookAdsAdminService,
    private store: Store<AppState>
  ) {}

  private loadAuthInfo(authInfo: AuthInfo) {
    const permissions = flattenPermissionCodes(authInfo.userInfo.permissions);
    this.ngxPermissionsService.loadPermissions(permissions);

    localStorage.setItem('accessToken', authInfo.token);
    localStorage.setItem('user', JSON.stringify(authInfo.userInfo));
    localStorage.setItem('lang', JSON.stringify(authInfo.userInfo.language || 'en'));
  }

  private fetchFacebookConfig() {
    return forkJoin({
      appConfig: from(this.facebookAdsAdminService.getAppConfig()),
      accountInfo: from(this.facebookAdsAdminService.getFacebookAccounts())
    }).pipe(
      map(({ appConfig, accountInfo }) => {
        this.store.dispatch(
          setFacebookConfig({
            appId: appConfig.data['app-id'],
            configId: appConfig.data['app-config-id']
          })
        );

        this.store.dispatch(
          setFacebookAccounts({
            facebookAccounts: accountInfo.data
          })
        );
        return appConfig.data;
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  login$ = createEffect(() =>
    this.action$.pipe(
      ofType(login),
      exhaustMap(action =>
        from(this.authService.login(action.email, action.password)).pipe(
          switchMap(res => {
            if (res.data.isOTPSent) {
              return of(otpRequired({ email: action.email }));
            }

            this.loadAuthInfo(res.data);

            return this.fetchFacebookConfig().pipe(
              map(() =>
                loginSuccess({
                  accessToken: res.data.token,
                  userInfo: res.data.userInfo
                })
              ),
              catchError(error => of(loginFailed({ error: error?.error?.code })))
            );
          }),
          catchError(error => of(loginFailed({ error: error?.error?.code })))
        )
      )
    )
  );

  verifyOTP$ = createEffect(() =>
    this.action$.pipe(
      ofType(verifyOtp),
      exhaustMap(action =>
        from(this.authService.verifyOTP(action.otp, action.email)).pipe(
          switchMap(res => {
            this.loadAuthInfo(res.data);

            return this.fetchFacebookConfig().pipe(
              map(() =>
                verifyOtpSuccess({
                  accessToken: res.data.token,
                  userInfo: res.data.userInfo
                })
              ),
              catchError(error => of(verifyOtpFailed({ error: error?.error?.code })))
            );
          }),
          catchError(error => of(verifyOtpFailed({ error: error?.error?.code })))
        )
      )
    )
  );

  loginRedirect$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(loginSuccess, verifyOtpSuccess),
        tap(() => {
          this.router.navigateByUrl('/');
        })
      ),
    {
      dispatch: false
    }
  );
}
