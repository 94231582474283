import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WeekDataDTO } from '@core/models/interfaces/marketing-dashboard/dashboard';
import { TranslateModule } from '@ngx-translate/core';
import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexChart,
  ChartComponent,
  ApexXAxis,
  ApexYAxis,
  ApexPlotOptions,
  NgApexchartsModule
} from 'ng-apexcharts';
import { TooltipModule } from 'primeng/tooltip';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'app-heatmap',
  standalone: true,
  imports: [TooltipModule, NgApexchartsModule, TranslateModule, FormsModule, CommonModule],
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss']
})
export class HeatMapComponent implements OnChanges {
  @Input() data: WeekDataDTO[];
  readonly translatePrefix = 'section-shop-dashboard.';
  chartOptions: ChartOptions = {
    series: [],
    chart: {
      type: 'heatmap'
    },
    dataLabels: {},
    title: {},
    xaxis: {},
    yaxis: {},
    plotOptions: {}
  };

  // HOURS_CONFIG = [
  //   ...Array.from({ length: 24 }, (_, i) => i),
  //   // ...Array.from({ length: 11 }, (_, i) => i + 1),
  // ];
  HOURS_CONFIG = ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '00:00'];

  COLOR_CONFIG = ['#DBEAFE', '#93C5FD', '#60A5FA', '#3B82F6', '#1D4ED8'];

  constructor() {}

  ngOnChanges() {
    this.data = [...this.data].reverse();
    const dataConverted = this.convertData(this.data);
    const ranges = this.getRanges(dataConverted);
    const fiveParts = this.divideRangeIntoFiveParts(ranges.min, ranges.max);

    this.chartOptions = {
      ...this.chartOptions,
      dataLabels: {
        enabled: false
      },
      chart: {
        height: 320,
        type: 'heatmap',
        toolbar: {
          show: false
        },
        parentHeightOffset: 0
      },
      title: {
        text: undefined
      },
      xaxis: {
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: '"Inter", "PlusJakartaSans", "Mulish", sans-serif'
          },
          offsetX: 0,
          offsetY: 0
        }
      }
    };

    if (this.data.length === 1) {
      this.chartOptions.chart.height = 84;
    } else {
      this.chartOptions.chart.height = 320;
    }

    this.chartOptions.series = this.data.map(item => ({
      name: item.day,
      data: item.value?.slice(0, 24) ?? []
    }));

    this.chartOptions.plotOptions = {
      heatmap: {
        radius: 4,
        distributed: true,
        colorScale: {
          ranges: [...fiveParts]
        }
      }
    };
  }

  getRanges(data: Array<number[]>) {
    let min = data?.[0]?.[0];
    let max = data?.[0]?.[0];
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (max < data[i][j]) {
          max = data[i][j];
        }
        if (min > data[i][j]) {
          min = data[i][j];
        }
      }
    }
    return { min, max };
  }

  divideRangeIntoFiveParts(min: number, max: number) {
    if (max === 0) {
      return [{ from: 0, to: 0, color: this.COLOR_CONFIG[0] }];
    }

    let partSize = (max - min) / 5;
    let parts = [];

    for (let i = 0; i < 5; i++) {
      let partMin = min + partSize * i;
      let partMax = partMin + partSize - (i < 4 ? 0.0001 : 0);
      parts.push({
        from: partMin,
        to: i < 4 ? partMax : max,
        color: this.COLOR_CONFIG[i]
      });
    }

    return parts;
  }

  convertData(data: WeekDataDTO[]): Array<number[]> {
    const results: Array<number[]> = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].value) {
        results.push(data[i].value?.slice(0, 24) as number[]);
      } else {
        results.push([]);
      }
    }
    return results;
  }
}
