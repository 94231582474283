import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { APP_VERSION } from '@core/constants/common';
import { ReportService } from '@core/services/report.service';
import { Store } from '@ngrx/store';
import { HeaderComponent } from '@shared/components/header/header.component';
import { SidebarComponent } from '@shared/components/sidebar/sidebar.component';
import { AppState } from '@state/app.state';
import { selectFacebookConfig } from '@state/system/system.selectors';
import { environment } from 'src/environments/environment';

declare let FB: any;

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, SidebarComponent],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent implements OnInit {
  readonly appVersion = APP_VERSION;
  readonly VERSION: string = 'v21.0';

  appId: string = '';

  constructor(
    private reportService: ReportService,
    private ngZone: NgZone,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    //Call start
    if (environment.ENV !== 'LOCAL') {
      this.reportService.logEventReport('ONLINE').subscribe();
    }

    this.store.select(selectFacebookConfig).subscribe({
      next: data => {
        this.appId = data.appId;
      }
    });

    //SDK Facebook
    this.ngZone.runOutsideAngular(() => {
      this.initializeFacebookSDK();
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    if (event?.target?.activeElement?.tagName === 'A') {
      return;
    }
    // Call end
    this.reportService.logEventReport('end-online').subscribe();
  }

  private initializeFacebookSDK(): void {
    (window as any).fbAsyncInit = () => {
      FB.init({
        appId: this.appId,
        cookie: true,
        xfbml: true,
        version: this.VERSION
      });
    };

    // Download SDK if it does not exist
    if (!document.getElementById('facebook-jssdk')) {
      const script = document.createElement('script');
      script.id = 'facebook-jssdk';
      script.async = true;
      script.defer = true;
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      document.body.appendChild(script);
    }
  }
}
