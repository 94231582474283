import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DividerModule } from 'primeng/divider';
import { forkJoin, Subject } from 'rxjs';

import { AudienceColumn } from '@core/models/interfaces/columns/columns';
import { GroupColumn, GroupColumnExpand } from '@core/models/interfaces/columns/group-column';
import { ColumnService } from '@core/services/column/column.service';
import { GroupColumnService } from '@core/services/column/group-column.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { FormControlComponent } from '@shared/components/form-control/form-control.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-export-report-dashboard',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DragDropModule,
    DividerModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    DialogModule,
    ButtonComponent,
    SearchInputComponent,
    CheckboxModule,
    FormControlComponent,
    InputTextModule
  ],
  templateUrl: './export-report-dashboard.component.html',
  styleUrl: './export-report-dashboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportReportDashboardComponent implements OnInit {
  readonly translatePrefix = 'lead-management.';
  readonly messagePrefix = 'lead-management.message.';
  readonly unsubscribe$ = new Subject();

  exportName = new FormControl();

  isEditing: boolean = false;
  isVisible: boolean = false;

  columnActivated = '';
  columnsSelected: string[] = [];

  groupCols: GroupColumnExpand[] = [];
  allCols: AudienceColumn[] = [];

  get objectColumn() {
    return this.allCols.reduce(
      (acc, { code, name }) => {
        acc[code] = name;
        return acc;
      },
      {} as { [key: string]: string }
    );
  }

  constructor(
    private columnService: ColumnService,
    private groupColumnService: GroupColumnService
  ) {}

  ngOnInit(): void {
    this.fetchAllColumns();
  }

  addExpandField(data: GroupColumn): GroupColumnExpand {
    function processNode(node: GroupColumn): GroupColumnExpand {
      const newNode: GroupColumnExpand = {
        ...node,
        expand: true,
        children: node.children ? node.children.map(child => processNode(child)) : []
      };

      return newNode;
    }

    return processNode(data);
  }

  fetchAllColumns() {
    forkJoin([this.groupColumnService.getAllGroupColumns(), this.columnService.getAllColumns()]).subscribe({
      next: results => {
        const [groupColsRes, colsRes] = results;
        this.columnActivated = 'PARENT_' + groupColsRes.data?.[0]?.code;
        this.groupCols = groupColsRes.data.map(item => this.addExpandField(item));
        this.allCols = colsRes.data;

        this.groupCols.forEach(group => {
          group.children.forEach(children => {
            children.children = this.allCols
              .filter(item => {
                return item.group?.id === children.id;
              })
              .map(item => ({
                id: item.id,
                name: item.name,
                code: item.code,
                position: item.position,
                children: []
              }));
          });
        });
      }
    });
  }

  onDropColumns(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columnsSelected, event.previousIndex, event.currentIndex);
  }

  scrollTo(sectionId: string): void {
    this.columnActivated = sectionId;
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  onExport() {}

  onRemoveSelectedColumn(code: string) {
    this.columnsSelected = [...this.columnsSelected].filter(item => item !== code);
  }
}
