import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

import { LANGUAGES } from '@core/constants/translate';
import { Store } from '@ngrx/store';
import { ToastMessageComponent } from '@shared/components/toast-message/toast-message.component';
import { AppState } from '@state/app.state';
import { selectUserInfo } from '@state/auth/auth.selectors';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, ToastMessageComponent, CommonModule, ConfirmDialogModule, NgxPermissionsModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class AppComponent implements OnInit, OnDestroy {
  readonly unsubscribe$ = new Subject<void>();
  lang: string = '';

  constructor(
    private translateService: TranslateService,
    private primeNGConfig: PrimeNGConfig,
    private titleService: Title,
    private router: Router,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.store
      .select(selectUserInfo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userInfo => {
        if (userInfo) {
          this.lang = userInfo.language ?? 'en';
          this.initLanguage(this.lang);
        }
      });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.titleService.setTitle(environment.APP_NAME);
    });
  }

  initLanguage(lang: string) {
    this.translateService.use(LANGUAGES.includes(lang) ? lang : 'en');
    this.translateService.get('PRIMENG').subscribe(res => {
      this.primeNGConfig.setTranslation(res);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
