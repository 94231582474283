import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { StorageService } from '@core/services/storage.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-custom-image',
  templateUrl: './custom-image.component.html',
  styleUrls: ['./custom-image.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class CustomImageComponent implements OnChanges, OnDestroy {
  readonly unsubscribe$ = new Subject();
  @Input({ required: true }) imgId = '';
  @Input({ required: true }) alt = '';
  @Input() defaultImage = '/assets/images/default/avatar.webp';

  @Input() styleClass = '';
  @Input() imageClass = '';
  imageLoaded = false;
  imageUrl: SafeUrl = '';

  constructor(
    public storageService: StorageService
    // private sanitizer: DomSanitizer
  ) {}

  ngOnChanges() {
    if (this.imgId) {
      this.imageUrl = this.storageService.getFileUrl(this.imgId);
      // this.storageService
      //   .getFile(this.imgId)
      //   .pipe(takeUntil(this.unsubscribe$))
      //   .subscribe({
      //     next: res => {
      //       this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(res));
      //       this.imageLoader = false;
      //     },
      //     error: () => {
      //       this.imageUrl = this.defaultImage;
      //       this.imageLoader = false;
      //     }
      //   });
    }
  }

  onError() {
    this.imageLoaded = true;
    this.imageUrl = this.defaultImage;
  }

  onImageLoad() {
    this.imageLoaded = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
