import { Component, OnDestroy, OnInit } from '@angular/core';
import { AudienceAndInsightsComponent } from './components/audience-and-insights/audience-and-insights.component';
import { PerformanceOverviewComponent } from './components/performance-overview/performance-overview.component';
import { ReachEngagementPerformanceComponent } from './components/reach-engagement-performance/reach-engagement-performance.component';
import { AdAccountSelectComponent } from '@shared/components/ad-account-select/ad-account-select.component';
import { AdAccount, AdAccountWithBusiness } from '@core/models/interfaces/facebook/integration';
import { FacebookAdsAdminService } from '@core/services/facebook.service.ts/facebook-ads-admin.service';
import { Store } from '@ngrx/store';
import { selectFacebookAccountSelected } from '@state/auth/auth.selectors';
import { AppState } from '@state/app.state';
import { Subject, takeUntil } from 'rxjs';
import { CustomCalendarComponent } from '@shared/components/custom-calendar/custom-calendar.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ExportReportDashboardComponent } from './components/export-report-dashboard/export-report-dashboard.component';

@Component({
  selector: 'app-marketing-dashboard',
  standalone: true,
  imports: [
    AudienceAndInsightsComponent,
    PerformanceOverviewComponent,
    ReachEngagementPerformanceComponent,
    AdAccountSelectComponent,
    CustomCalendarComponent,
    ButtonComponent,
    ExportReportDashboardComponent
  ],
  templateUrl: './marketing-dashboard.component.html',
  styleUrl: './marketing-dashboard.component.scss'
})
export class MarketingDashboardComponent implements OnInit, OnDestroy {
  readonly unsubscribe$ = new Subject();

  initial: boolean = false;
  accessToken: string = '';
  accountBusinesses: AdAccountWithBusiness[] = [];
  adAccountSelected: AdAccount = {
    id: '',
    name: ''
  };

  constructor(
    private facebookAdsAdminService: FacebookAdsAdminService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.getAccessToken();
    this.initData();
  }

  getAccessToken() {
    this.store
      .select(selectFacebookAccountSelected)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(acc => {
        if (acc) {
          this.accessToken = acc.accessToken;
        }
      });
  }

  initData() {
    this.facebookAdsAdminService
      .getAdAccountsWithBusiness(this.accessToken)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.accountBusinesses = res.data;
          if (this.accountBusinesses.length) {
            const adAccountStores = sessionStorage.getItem('adAccountInit');
            if (adAccountStores) {
              this.adAccountSelected = JSON.parse(adAccountStores);
            } else {
              this.adAccountSelected = this.accountBusinesses?.[0].act_account?.[0];
            }
          }
          this.initial = true;
        },
        error: () => {
          this.initial = true;
        }
      });
  }

  onChangeAdAccount() {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
