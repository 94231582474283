<div class="flex flex-row ml-auto justify-between items-center">
  <span class="text-neutral-600 text-4 font-normal">{{ 'AUDIENCE AND INSIGHTS' }}</span>
  <app-custom-calendar [(datesSelected)]="datesSelected"></app-custom-calendar>
</div>

<div class="grid grid-cols-2 gap-6 mt-4">
  <div class="flex flex-col justify-between rounded-md border px-6 py-5 col-span-1">
    <div>
      <div class="flex flex-row justify-between items-center">
        <h2 class="font-semibold text-4">{{ 'Age and Gender' }}</h2>
        <p-dropdown
          styleClass="h-9 w-full flex ml-auto"
          [options]="generalDropdown"
          appendTo="body"
          optionLabel="name"
          optionValue="code"
          dropdownIcon="icon-wl-arrow-drop-down"
          [(ngModel)]="selectedDataTypeAgeAndGender"
          (onChange)="onChangeDataType()"></p-dropdown>
      </div>
      <span class="font-semibold text-7">{{ '$224.32' }}</span>

      <div class="flex-1 pt-5 pb-4">
        <p-chart
          height="256px"
          width="100%"
          type="bar"
          [data]="ageAndGenderChartData"
          [options]="barChartOptions"></p-chart>
      </div>
    </div>

    <app-doughnut-chart-legend [options]="leadsByGender"></app-doughnut-chart-legend>
  </div>

  <div class="flex flex-col justify-between rounded-md border px-6 py-5 col-span-1">
    <div>
      <div class="flex flex-row justify-between items-center">
        <h2 class="font-semibold text-4">{{ 'Day and time' }}</h2>
        <p-dropdown
          styleClass="h-9 w-full flex ml-auto"
          [options]="generalDropdown"
          appendTo="body"
          optionLabel="name"
          optionValue="code"
          dropdownIcon="icon-wl-arrow-drop-down"
          [(ngModel)]="selectedDataTypeDayAndTime"
          (onChange)="onChangeDataType()"></p-dropdown>
      </div>
      <span class="font-semibold text-7 pb-5">{{ '$224.32' }}</span>
      <app-heatmap [data]="heatmap"></app-heatmap>
    </div>
  </div>

  <div class="flex flex-col justify-between rounded-md border px-6 py-5 col-span-1">
    <div>
      <div class="flex flex-row justify-between items-center">
        <h2 class="font-semibold text-4">{{ 'Top states' }}</h2>
        <p-dropdown
          styleClass="h-9 w-full flex ml-auto"
          [options]="topStateDropdown"
          appendTo="body"
          optionLabel="name"
          optionValue="code"
          dropdownIcon="icon-wl-arrow-drop-down"
          [(ngModel)]="selectedDataTypeTopState"
          (onChange)="onChangeDataType()"></p-dropdown>
      </div>
      <span class="font-semibold text-7 pb-7">{{ '$224.32' }}</span>

      <div class="flex-1 pt-5 pb-4">
        <p-chart
          height="256px"
          width="100%"
          type="bar"
          [data]="topStatesChartData"
          [options]="barChartOptions"></p-chart>
      </div>
    </div>
  </div>

  <div class="flex flex-col justify-between rounded-md border px-6 py-5 col-span-1">
    <div>
      <div class="flex flex-row justify-between items-center">
        <h2 class="font-semibold text-4">{{ 'Placements' }}</h2>
        <p-dropdown
          styleClass="h-9 w-full flex ml-auto"
          [options]="generalDropdown"
          appendTo="body"
          optionLabel="name"
          optionValue="code"
          dropdownIcon="icon-wl-arrow-drop-down"
          [(ngModel)]="selectedDataTypePlacements"
          (onChange)="onChangeDataType()"></p-dropdown>
      </div>
      <span class="font-semibold text-7 pb-5">{{ '$224.32' }}</span>

      <div class="flex-1 pt-5 pb-4">
        <p-chart
          height="256px"
          width="100%"
          type="bar"
          [data]="placementsChartData"
          [options]="barChartOptions"></p-chart>
      </div>
    </div>
  </div>
</div>
