import { Component } from '@angular/core';
import { UNIT_NUMBER } from '@core/enum/dashboard';
import { DashboardStatisticComponent } from '@shared/components/dashboard-statistic/dashboard-statistic.component';
import { ChartData } from 'chart.js';
import { ChartModule } from 'primeng/chart';
import { BarLineChartComponent } from '../bar-line-chart/bar-line-chart.component';

@Component({
  selector: 'app-reach-engagement-performance',
  standalone: true,
  imports: [DashboardStatisticComponent, ChartModule, BarLineChartComponent],
  templateUrl: './reach-engagement-performance.component.html',
  styleUrl: './reach-engagement-performance.component.scss'
})
export class ReachEngagementPerformanceComponent {
  readonly UNIT_NUMBER = UNIT_NUMBER;

  statistics = [
    { title: 'AVERAGE CPC', amount: 0.42, rate: 12.1, dataType: UNIT_NUMBER.CURRENCY },
    { title: 'COST PER ENGAGEMENT', amount: 0.96, rate: -12.2, dataType: UNIT_NUMBER.CURRENCY },
    { title: 'COST PER THRUPLAY', amount: 0.42, rate: 12.1, dataType: UNIT_NUMBER.CURRENCY }
  ];

  impressions: ChartData;
  clicks: ChartData;
  CTR: ChartData;

  ngOnInit() {
    const array: number[] = [];
    for (let i = 1; i < 31; i += 3) {
      array.push(i);
    }

    this.impressions = {
      labels: array,
      datasets: [
        {
          label: 'Impressions',
          data: array.map((_: number, i: number) => {
            return this.getRandomNumber(200);
          }),
          backgroundColor: array.map((_: number) => {
            return '#3B82F6';
          }),
          borderColor: '#3B82F6',
          borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointHitRadius: 10
        }
      ]
    };

    this.clicks = {
      labels: array,
      datasets: [
        {
          label: 'Clicks',
          data: array.map((_: number, i: number) => {
            return this.getRandomNumber(200);
          }),
          backgroundColor: array.map((_: number) => {
            return '#06B6D4 ';
          }),
          borderColor: '#06B6D4 ',
          borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointHitRadius: 10
        }
      ]
    };

    this.CTR = {
      labels: array,
      datasets: [
        {
          label: 'CTR',
          data: array.map((_: number, i: number) => {
            return this.getRandomNumber(2);
          }),
          backgroundColor: array.map((_: number) => {
            return '#3B82F6';
          }),
          borderColor: '#3B82F6',
          borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 5,
          pointHitRadius: 10
        }
      ]
    };
  }

  getRandomNumber(multiplier: number) {
    return Math.floor(Math.random() * 41) * multiplier;
  }
}
