<div class="flex flex-col gap-5 border rounded-md py-5">
  <div class="flex items-center justify-between px-6">
    <div class="flex flex-col gap-1">
      <span class="text-4 font-semibold">{{ title }}</span>
      <span class="text-7 font-semibold">
        @if (dataType === UNIT_NUMBER.NUMBER) {
          {{ totalAmount | number }}
        } @else if (dataType === UNIT_NUMBER.PERCENT) {
          {{ totalAmount }}%
        } @else if (dataType === UNIT_NUMBER.CURRENCY) {
          {{ totalAmount | currency }}
        }
      </span>
    </div>

    <p-selectButton
      styleClass="my-4"
      [options]="tabOptions"
      [allowEmpty]="false"
      [(ngModel)]="tabActive"
      optionValue="value">
      <ng-template pTemplate="item" let-item>
        <div>
          {{ item.title }}
        </div>
      </ng-template>
    </p-selectButton>
  </div>
  <div class="px-6">
    @if (tabActive === 'BAR') {
      <p-chart type="bar" [data]="data" height="280px" [options]="chartOptions"></p-chart>
    } @else {
      <p-chart type="line" [data]="data" height="280px" [options]="chartOptions"></p-chart>
    }
  </div>
</div>
