import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlowDetails, PartnerAppDetails } from '@core/models/interfaces/facebook/partner-apps';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomLoadingComponent } from '@shared/components/custom-loading/custom-loading.component';
import { EmptyComponent } from '@shared/components/empty/empty.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AccordionModule } from 'primeng/accordion';
import { MenuItem } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressBarModule } from 'primeng/progressbar';

@Component({
  selector: 'app-partner-apps-view',
  standalone: true,
  imports: [
    SearchInputComponent,
    AccordionModule,
    CommonModule,
    PanelMenuModule,
    MenuModule,
    RouterModule,
    TranslateModule,
    RouterModule,
    EmptyComponent,
    CustomLoadingComponent,
    NgxPermissionsModule,
    DialogModule,
    FloatLabelModule,
    FormsModule,
    ReactiveFormsModule,
    FloatLabelModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    TranslateModule,
    InputTextModule,
    EditorModule,
    ButtonComponent,
    ReactiveFormsModule,
    ProgressBarModule,
    FloatLabelModule,
    FormsModule,
    CustomLoadingComponent,
    InputTextareaModule,
    AvatarModule
  ],
  templateUrl: './partner-apps-view.component.html',
  styleUrl: './partner-apps-view.component.scss'
})
export class PartnerAppsViewComponent {
  @Input() partnerAppActive = '';
  @Input() partnerAppName: string = '';
  @Input() partnerApps: PartnerAppDetails[] = [];
  @Input() messageFlowActive: FlowDetails;

  @Output() messageFlowActiveChange = new EventEmitter<FlowDetails>();
  @Output() partnerAppNameChange = new EventEmitter<string>();
  @Output() createMessageFlow = new EventEmitter<string>();
  @Output() onDeleteMessageFlowChange = new EventEmitter<string>();
  @Output() partnerAppActiveChange = new EventEmitter<string>();
  @Output() deletePartnerApps = new EventEmitter<{id: string, name: string}>();
  @Output() searchPartnerApps = new EventEmitter<string>();

  isCreateApp: boolean = false;
  loading: boolean = false;
  actions: MenuItem[] = [
    // {
    //   id: 'EDIT',
    //   label: 'edit',
    //   icon: 'icon-wl-edit',

    // },
    {
      id: 'DELETE',
      label: 'delete',
      icon: 'icon-wl-delete',
      styleClass: 'text-red-600'
    }
  ];

  ngOnChanges() {
    this.partnerApps = this.partnerApps.map((item: PartnerAppDetails) => ({
      ...item,
      expanded: true
    }));
  }

  onEditPartnerApps() {}

  onDeletePartnerAps(id: string, name: string) {
    this.deletePartnerApps.emit({id, name});
  }

  onClickItem(item: PartnerAppDetails) {
    this.partnerAppActive = item.id as string;
    this.partnerAppActiveChange.emit(this.partnerAppActive);
  }

  onClickSubItem(item: FlowDetails) {
    this.partnerAppActive = item.id as string;
    this.messageFlowActiveChange.emit(item);
  }

  onExpanded(item: MenuItem) {
    const index = this.partnerApps.findIndex((group: any) => group.id === item.id);
    this.partnerApps[index].expanded = !this.partnerApps[index].expanded;
  }

  onSearch(event: string) {
    this.searchPartnerApps.emit(event);
  }

  onCreateMessageFlow(partnerAppId: string) {
    this.createMessageFlow.emit(partnerAppId);
  }

  onAction(action: MenuItem, partnerApps: PartnerAppDetails) {
    // this.groupSelected = { ...group };

    switch (action.id) {
      case 'EDIT': {
        this.onEditGroup();
        break;
      }

      case 'DELETE': {
        this.onDeletePartnerAps(partnerApps.id, partnerApps.name);
        break;
      }

      // case 'CLONE': {
      //   this.onCloneGroup(group.id, 'GROUP');
      // }
    }
  }

  onCreatePartnerApps() {
    this.isCreateApp = true;
    this.partnerAppNameChange.emit(this.partnerAppName);
    this.isCreateApp = false;
  }

  onDeleteMessageFlow(event: string) {
    this.onDeleteMessageFlowChange.emit(event);
  }

  onEditGroup() {}
}
