import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreateMessageFlow } from './../../models/interfaces/facebook/partner-apps';
import { ResponseApi, ResponseListApi } from './../../models/interfaces/common';
import { FlowDetails } from './../../models/interfaces/facebook/partner-apps';

@Injectable({ providedIn: 'root' })
export class FacebookMessageFlowService {
  readonly baseURL: string = `${environment.API_URL}/crm/facebook/json-flows`;

  constructor(private httpClient: HttpClient) {}

  createMessageFlow(body: CreateMessageFlow) {
    return this.httpClient.post<ResponseApi<FlowDetails>>(this.baseURL, body);
  }

  deleteMessageFlow(id: string) {
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }

  editMessageFlow(body: CreateMessageFlow, id: string) {
    return this.httpClient.put<ResponseApi<FlowDetails>>(`${this.baseURL}/${id}`, body);
  }

  getMessageFlowById(id: string) {
    return this.httpClient.get<ResponseApi<FlowDetails>>(`${this.baseURL}/${id}`);
  }

  getMessageFlowByPage(id: string, page?: string, size?: string) {
    let params = new HttpParams();
    params = params.set('partnerAppId', id);
    if (page) {
      params = params.set('page', page);
    }
    if (size) {
      params = params.set('size', size);
    }
    return this.httpClient.get<ResponseListApi<FlowDetails>>(`${this.baseURL}/page`, {
      params
    });
  }
}
