import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FacebookAccount } from '@core/models/interfaces/facebook/facebook-account';
import { AdAccountWithBusiness, IntegrationData, IntegrationItem } from '@core/models/interfaces/facebook/integration';
import { ResponseApi } from '@core/models/interfaces/common';
import { FanpageDetails } from '@core/models/interfaces/facebook/facebook-ads';

@Injectable({
  providedIn: 'root'
})
export class FacebookAdsAdminService {
  private baseUrl = `${environment.API_URL}/crm/facebook-ads`;

  constructor(private httpClient: HttpClient) {}

  getAppConfig() {
    return this.httpClient.get<
      ResponseApi<{
        'app-id': string;
        'app-config-id': string;
      }>
    >(`${this.baseUrl}/app-config`);
  }

  authentication(accessToken: string) {
    return this.httpClient.post<FacebookAccount>(`${this.baseUrl}/authentication`, {
      accessToken
    });
  }

  getFacebookAccounts() {
    return this.httpClient.get<ResponseApi<FacebookAccount[]>>(`${this.baseUrl}/accounts`);
  }

  getBusinessPortfolio(accessToken: string) {
    return this.httpClient.get<ResponseApi<IntegrationItem[]>>(`${this.baseUrl}/business-portfolio`, {
      params: { accessToken }
    });
  }

  getAdAccountsWithBusiness(accessToken: string, limit?: number) {
    const queryParams = new HttpParams({
      fromObject: {
        accessToken,
        ...(limit && { limit: limit.toString() })
      }
    });
    return this.httpClient.get<ResponseApi<AdAccountWithBusiness[]>>(`${this.baseUrl}/ad-accounts`, {
      params: queryParams
    });
  }

  getFanPage(accessToken: string) {
    return this.httpClient.get<ResponseApi<FanpageDetails[]>>(`${this.baseUrl}/fan-pages`, {
      params: {
        accessToken
      }
    });
  }

  disconnectAccount(id: string) {
    return this.httpClient.delete(`${this.baseUrl}/disconnect-account/${id}`);
  }

  getFanPageByAdAccount(adAccountId: string, accessToken: string) {
    return this.httpClient.get<ResponseApi<FanpageDetails[]>>(`${this.baseUrl}/adaccounts-pages`, {
      params: { adAccountId, accessToken }
    });
  }

  // VISIBILITY
  getFanPagesVisibility(accessToken: string) {
    return this.httpClient.get<ResponseApi<IntegrationItem[]>>(`${this.baseUrl}/fan-pages-with-visibility`, {
      params: { accessToken }
    });
  }

  getBusinessVisibility(accessToken: string) {
    return this.httpClient.get<ResponseApi<IntegrationItem[]>>(`${this.baseUrl}/business-portfolio-visibility`, {
      params: { accessToken }
    });
  }

  getAdAccountsVisibility(accessToken: string) {
    return this.httpClient.get<ResponseApi<AdAccountWithBusiness[]>>(`${this.baseUrl}/ad-accounts-visibility`, {
      params: { accessToken }
    });
  }

  hideAndShowFanPage(fanPageId: string, isVisible: boolean) {
    return this.httpClient.put(
      `${this.baseUrl}/fan-pages/${fanPageId}/hide`,
      {},
      {
        params: { isVisible }
      }
    );
  }

  hideAndShowBusiness(businessId: string, isVisible: boolean) {
    return this.httpClient.put(
      `${this.baseUrl}/business-portfolios/${businessId}/hide`,
      {},
      {
        params: { isVisible }
      }
    );
  }

  hideAndShowAdAccount(actAccountId: string, isVisible: boolean) {
    return this.httpClient.put(
      `${this.baseUrl}/ad-accounts/hide`,
      {},
      {
        params: { actAccountId, isVisible }
      }
    );
  }
}
