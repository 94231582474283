<div class="flex flex-col gap-6">
  <div class="grid grid-cols-2 md:grid-cols-3 gap-6">
    @for (statistic of performanceStatistics; track $index) {
      <div class="flex-1">
        <app-dashboard-statistic
          [title]="statistic.title"
          [amount]="statistic.amount"
          [dataType]="statistic.dataType"
          [rate]="statistic.rate"
          [isAll]="statistic.isAll"></app-dashboard-statistic>
      </div>
    }
  </div>

  <div class="grid grid-cols-2 gap-6">
    <!-- Notes -->
    <div class="border rounded-md pb-6">
      <div class="px-6 py-5 flex gap-3 items-start justify-between">
        <div class="flex flex-col gap-1">
          <h2 class="text-4 font-semibold">{{ translatePrefix + 'notes' | translate }}</h2>
          <p class="text-7 font-semibold">{{ totalNotes | number }}</p>
        </div>

        <p-selectButton
          [options]="periodOptions"
          [(ngModel)]="notesPeriodActive"
          [allowEmpty]="false"
          optionValue="value"
          (onChange)="fetchNoteChartData()">
          <ng-template pTemplate="item" let-item>
            <div>
              {{ item.title }}
            </div>
          </ng-template>
        </p-selectButton>
      </div>

      <div class="px-6 pt-2 pb-4">
        <p-chart type="bar" height="256px" [data]="notesChartData" [options]="barChartOptions"></p-chart>
      </div>
    </div>

    <!-- Tasks -->
    <div class="border rounded-md pb-6">
      <div class="px-6 py-5 flex gap-3 items-start justify-between">
        <div class="flex flex-col gap-1">
          <h2 class="text-4 font-semibold">{{ translatePrefix + 'tasks' | translate }}</h2>
          <p class="text-7 font-semibold">{{ totalTasks | number }}</p>
        </div>

        <p-selectButton
          [options]="periodOptions"
          [(ngModel)]="tasksPeriodActive"
          [allowEmpty]="false"
          optionValue="value"
          (onChange)="fetchTaskChartData()">
          <ng-template pTemplate="item" let-item>
            <div>
              {{ item.title }}
            </div>
          </ng-template>
        </p-selectButton>
      </div>

      <div class="px-6 pt-2 pb-4">
        <p-chart type="bar" height="256px" [data]="tasksChartData" [options]="barChartOptions"></p-chart>
      </div>
    </div>

    <!-- Appointments -->
    <div class="border rounded-md pb-6">
      <div class="px-6 py-5 flex gap-3 items-start justify-between">
        <div class="flex flex-col gap-1">
          <h2 class="text-4 font-semibold">{{ translatePrefix + 'appointments' | translate }}</h2>
          <p class="text-7 font-semibold">{{ totalAppointments | number }}</p>
        </div>

        <p-selectButton
          [options]="periodOptions"
          [(ngModel)]="appointmentsPeriodActive"
          [allowEmpty]="false"
          optionValue="value"
          (onChange)="fetchAppointmentChartData()">
          <ng-template pTemplate="item" let-item>
            <div>
              {{ item.title }}
            </div>
          </ng-template>
        </p-selectButton>
      </div>

      <div class="px-6 pt-2 pb-4">
        <p-chart type="bar" height="256px" [data]="appointmentsChartData" [options]="barChartOptions"></p-chart>
      </div>
    </div>

    <!-- Leads -->
    <div class="border rounded-md pb-6">
      <div class="px-6 py-5 flex gap-3 items-start justify-between">
        <div class="flex flex-col gap-1">
          <h2 class="text-4 font-semibold">{{ translatePrefix + 'leads' | translate }}</h2>
          <p class="text-7 font-semibold">{{ totalLeads | number }}</p>
        </div>

        <p-selectButton
          [options]="periodOptions"
          [(ngModel)]="leadsPeriodActive"
          [allowEmpty]="false"
          optionValue="value"
          (onChange)="fetchLeadChartData()">
          <ng-template pTemplate="item" let-item>
            <div>
              {{ item.title }}
            </div>
          </ng-template>
        </p-selectButton>
      </div>

      <div class="px-6 pt-2 pb-4">
        <p-chart type="bar" height="256px" [data]="leadsChartData" [options]="barChartOptions"></p-chart>
      </div>
    </div>

    <!-- Calls -->
    <div class="border rounded-md pb-6">
      <div class="px-6 py-5 flex gap-3 items-start justify-between">
        <div class="flex flex-col gap-1">
          <h2 class="text-4 font-semibold">{{ translatePrefix + 'calls' | translate }}</h2>
          <p class="text-7 font-semibold">{{ totalCalls | number }}</p>
        </div>

        <p-selectButton
          [options]="periodOptions"
          [(ngModel)]="callsPeriodActive"
          [allowEmpty]="false"
          optionValue="value"
          (onChange)="fetchCallChartData()">
          <ng-template pTemplate="item" let-item>
            <div>
              {{ item.title }}
            </div>
          </ng-template>
        </p-selectButton>
      </div>

      <div class="px-6 pt-2 pb-4">
        <p-chart type="bar" height="256px" [data]="callsChartData" [options]="barChartOptions"></p-chart>
      </div>
    </div>

    <!-- Hours online -->
    <div class="border rounded-md pb-6">
      <div class="px-6 py-5 flex gap-3 items-start justify-between">
        <div class="flex flex-col gap-1">
          <h2 class="text-4 font-semibold">{{ translatePrefix + 'hours-online' | translate }}</h2>
          <p class="text-7 font-semibold">{{ totalHoursOnline | number }}</p>
        </div>

        <p-selectButton
          [options]="periodOptions"
          [(ngModel)]="hoursOnlinePeriodActive"
          [allowEmpty]="false"
          optionValue="value"
          (onChange)="fetchHourOnlineChartData()">
          <ng-template pTemplate="item" let-item>
            <div>
              {{ item.title }}
            </div>
          </ng-template>
        </p-selectButton>
      </div>

      <div class="px-6 pt-2 pb-4">
        <p-chart type="bar" height="256px" [data]="hoursOnlineChartData" [options]="barChartOptions"></p-chart>
      </div>
    </div>
  </div>
</div>
