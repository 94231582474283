import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AdAccount, AdAccountWithBusiness } from '@core/models/interfaces/facebook/integration';
import { OverlayModule } from 'primeng/overlay';

@Component({
  selector: 'app-ad-account-select',
  standalone: true,
  imports: [OverlayModule],
  templateUrl: './ad-account-select.component.html',
  styleUrl: './ad-account-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdAccountSelectComponent {
  @Input() accountBusinesses: AdAccountWithBusiness[] = [];
  @Input() adAccountSelected: AdAccount;
  @Output() adAccountSelectedChange = new EventEmitter<AdAccount>();

  isAccount: boolean = false;

  onSelectAdAccount(adAccount: AdAccount) {
    this.adAccountSelected = adAccount;
    this.adAccountSelectedChange.emit(this.adAccountSelected);
    this.isAccount = false;
    sessionStorage.setItem('adAccountInit', JSON.stringify(adAccount));
  }
}
