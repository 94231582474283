<div class="border rounded-sm">
  <!-- HEADER -->
  <div class="flex items-center justify-between h-11 pl-2 pr-2.5">
    <app-option-status-dropdown
      type="NOTE"
      [disabled]="!isEdit"
      [(visible)]="visible"
      [(selected)]="statusId"
      [options]="statuses"
      [totalOptions]="totalStatuses"
      [initValue]="statusInit"
      [isApplyBtn]="false"
      [showIcon]="true"
      inputClass="h-7 border-none w-fit !justify-start px-2"></app-option-status-dropdown>

    <!-- ICONS -->
    <div class="flex items-center">
      <div *ngxPermissionsOnly="Permissions.NOTE_PIN">
        <div class="w-7 h-7 flex items-center justify-center cursor-pointer" (click)="onPin()">
          @if (isPin) {
            <img src="/assets/images/icons/solid-pin.svg" alt="Icon pin" />
          } @else {
            <i class="text-4 icon-wl-pin"></i>
          }
        </div>
      </div>
      <ng-container
        *ngxPermissionsOnly="[
          Permissions.NOTE_APPEND_EDIT,
          Permissions.NOTE_APPEND_DELETE,
          isCreatedIn30Minutes(note.createdBy?.id, note.createdAt) ? Permissions.NOTE_ADD_APPEND : ''
        ]">
        <div
          *ngIf="userInfo.id === note.createdBy?.id"
          class="w-7 h-7 cursor-pointer flex items-center justify-center"
          [ngClass]="isMenu ? 'rounded-sm bg-neutral-200' : ''"
          (click)="menu.toggle($event)">
          <i class="icon-wl-dots-horizontal text-4 cursor-pointer"></i>
        </div>
      </ng-container>
      <p-menu appendTo="body" #menu [model]="actions" [popup]="true">
        <ng-template pTemplate="item" let-item>
          <a
            *ngxPermissionsOnly="[
              item.permission,
              (item.id === 'EDIT' || item.id === 'DELETE') && isCreatedIn30Minutes(note.createdBy?.id, note.createdAt)
                ? Permissions.NOTE_ADD_APPEND
                : null
            ]"
            pRipple
            [ngClass]="item.styleClass"
            class="flex items-center h-9 px-2 cursor-pointer">
            <i [class]="item.icon + ' text-6'"></i>
            <span class="ml-2 text-3">{{ 'action.' + item.label | translate }}</span>
          </a>
        </ng-template>
      </p-menu>
    </div>
  </div>

  <div class="flex flex-col pb-3 px-4">
    <!-- BODY -->

    <div class="relative" #noteElement>
      @if (isEdit) {
        <textarea
          [rows]="1"
          class="!shadow-none border-none p-0 w-full"
          pInputTextarea
          [autoResize]="true"
          (focus)="onEdit()"
          (input)="refreshLayout.emit()"
          [(ngModel)]="noteValue"></textarea>
      } @else {
        <div class="whitespace-pre-line break-words" [ngClass]="isLarge && !isViewMore ? 'line-clamp-4' : ''">
          {{ noteValue }}
        </div>
        @if (isLarge && !isEdit) {
          <span (click)="onToggleViewMore()" class="text-blue-600 hover:text-blue-700 cursor-pointer">{{
            (isViewMore ? 'action.view-less' : 'action.view-more') | translate
          }}</span>
        }
      }
    </div>

    @if (note?.policy || (!note?.policy && isEdit)) {
      <div class="mt-2 -ml-3">
        <p-dropdown
          appendTo="body"
          [disabled]="!isEdit"
          [readonly]="!isEdit"
          [filter]="true"
          [options]="policies"
          [(ngModel)]="policyId"
          optionValue="id"
          optionLabel="number"
          [placeholder]="translatePrefix + 'policy-info' | translate"
          styleClass="h-7 border-none !shadow-none px-0"
          [dropdownIcon]="!isEdit ? ' ' : 'icon-wl-arrow-drop-down'"></p-dropdown>
      </div>
    }

    @if (!isEdit) {
      <!-- FOOTER -->
      <div class="flex items-center justify-between text-2 mt-3">
        <div class="flex items-center gap-1.5">
          <app-custom-avatar
            [imgId]="note.createdBy?.avatar || ''"
            [name]="note.createdBy?.fullName || ''"
            size="2xs"></app-custom-avatar>
          <span class="font-semibold max-w-46" [ngClass]="{ truncate: fullNameCreatedBy.length > 25 }">{{
            note.createdBy?.fullName
          }}</span>
          @if (view === 'COMPACT') {
            @if (fullNameCreatedBy.length <= 10) {
              <div class="w-1 h-1 rounded-full bg-neutral-alpha-500A"></div>
              <span (click)="onAppends()" class="text-primary-600 cursor-pointer">
                {{ note.replyNotes?.length }}
                {{ (note.replyNotes.length > 1 ? 'common.appends' : 'common.append') | translate }}
              </span>
            }
          } @else {
            <div class="w-1 h-1 rounded-full bg-neutral-alpha-500A"></div>
            <span (click)="onAppends()" class="text-primary-600 cursor-pointer">
              {{ note.replyNotes?.length }}
              {{ (note.replyNotes.length > 1 ? 'common.appends' : 'common.append') | translate }}
            </span>
          }
        </div>
        @if (view === 'COMPACT') {
          @if (fullNameCreatedBy.length <= 20) {
            <div class="text-neutral-600">
              <span pTooltip="{{ note?.createdAt | date: 'MM/dd/yyyy hh:mm' }}" tooltipPosition="top">
                {{ isExpanded ? (note?.createdAt | date: 'MM/dd/yyyy hh:mm') : (note?.createdAt | date: 'MM/dd/yyyy') }}
              </span>
            </div>
          }
        } @else {
          <div class="text-neutral-600">
            <span pTooltip="{{ note?.createdAt | date: 'MM/dd/yyyy hh:mm' }}" tooltipPosition="top">
              {{ isExpanded ? (note?.createdAt | date: 'MM/dd/yyyy hh:mm') : (note?.createdAt | date: 'MM/dd/yyyy') }}
            </span>
          </div>
        }
      </div>
      @if (view === 'COMPACT') {
        <div class="flex items-center justify-between text-2 mt-3 gap-1.5">
          @if (fullNameCreatedBy.length > 10) {
            <div class="w-1 h-1 rounded-full bg-neutral-alpha-500A"></div>
            <span (click)="onAppends()" class="flex-1 text-primary-600 cursor-pointer">
              {{ note.replyNotes?.length }}
              {{ (note.replyNotes.length > 1 ? 'common.appends' : 'common.append') | translate }}
            </span>
          }
          @if (fullNameCreatedBy.length > 20) {
            <div class="text-neutral-600">
              <span pTooltip="{{ note?.createdAt | date: 'MM/dd/yyyy hh:mm' }}" tooltipPosition="top">
                {{ isExpanded ? (note?.createdAt | date: 'MM/dd/yyyy hh:mm') : (note?.createdAt | date: 'MM/dd/yyyy') }}
              </span>
            </div>
          }
        </div>
      }
    }
  </div>

  <!-- COMMENTS -->
  @if (isEdit) {
    <div class="h-12 flex items-center justify-end px-3 gap-2 border-t">
      <app-button
        [label]="'action.cancel' | translate"
        variant="ghost-secondary"
        size="xs"
        (onClick)="onCancel()"></app-button>
      <app-button [label]="'action.save' | translate" size="xs" (onClick)="onUpdate()"></app-button>
    </div>
  } @else {
    @if (isComment) {
      <div class="py-3 border-t">
        <div class="flex flex-col gap-1.5 max-h-[200px] overflow-y-auto px-4">
          @for (reply of note.replyNotes; track reply.id) {
            @if (isEditAppend && appendIdSelected === reply.id) {
              <div class="flex items-start gap-1.5 py-1.5">
                <app-custom-avatar
                  [imgId]="userInfo?.avatar || ''"
                  [name]="userInfo?.fullName || ''"
                  size="2xs"></app-custom-avatar>
                <textarea
                  [(ngModel)]="appendNote"
                  class="h-20 w-full !shadow-none focus:border-blue-600"
                  pInputTextarea
                  [autoResize]="true"
                  [rows]="4"
                  [placeholder]="translatePrefix + 'enter-note' | translate"
                  (keydown.enter)="onEditAppend($event, $index)"
                  (keydown.esc)="onCancelEditAppend()">
                </textarea>
              </div>
            } @else {
              <div class="flex flex-col gap-1 pb-1.5">
                <div class="flex items-center gap-1.5">
                  <app-custom-avatar
                    [imgId]="reply?.createdBy?.avatar || ''"
                    [name]="reply?.createdBy?.fullName || ''"
                    size="2xs"></app-custom-avatar>
                  <span class="text-2 font-semibold flex-1">{{ reply?.createdBy?.fullName }}</span>

                  <div
                    *ngxPermissionsOnly="[
                      Permissions.NOTE_APPEND_EDIT,
                      Permissions.NOTE_APPEND_DELETE,
                      isCreatedIn30Minutes(reply?.createdBy?.id, reply.createdAt) ? Permissions.NOTE_ADD_APPEND : ''
                    ]"
                    class="h-4 flex items-center justify-center rounded-sm cursor-pointer"
                    (click)="appendMenu.toggle($event); onSelectReply(reply)">
                    <i class="icon-wl-dots-horizontal text-4"></i>

                    <p-menu #appendMenu appendTo="body" [model]="appendActions" [popup]="true">
                      <ng-template pTemplate="item" let-item>
                        <a
                          *ngxPermissionsOnly="[
                            item.permission,
                            (item.id === 'EDIT' || item.id === 'DELETE') &&
                            isCreatedIn30Minutes(reply.createdBy?.id, reply.createdAt)
                              ? Permissions.NOTE_ADD_APPEND
                              : null
                          ]"
                          pRipple
                          [ngClass]="item.styleClass"
                          class="flex items-center h-9 px-2 cursor-pointer">
                          <i [class]="item.icon + ' text-6'"></i>
                          <span class="ml-2 text-3">{{ 'action.' + item.label | translate }}</span>
                        </a>
                      </ng-template>
                    </p-menu>
                  </div>
                </div>

                <div class="ml-5">
                  <div class="whitespace-pre-line break-words">
                    {{ expandedReplies[reply.id] ? reply?.notes : (reply?.notes | slice: 0 : 100)
                    }}{{ !expandedReplies[reply.id] && (reply?.notes?.length ?? 0) > 100 ? '...' : '' }}
                  </div>
                  <span
                    class="text-blue-600 hover:text-blue-700 cursor-pointer"
                    *ngIf="(reply?.notes?.length ?? 0) > 100"
                    (click)="toggleReply(reply.id)">
                    {{ expandedReplies[reply.id] ? 'view less' : 'view more' }}
                  </span>
                </div>

                <div class="flex justify-end">
                  <span
                    class="text-2 text-neutral-600"
                    pTooltip="{{ reply?.createdAt | date: 'MM/dd/yyyy hh:mm' }}"
                    tooltipPosition="top">
                    @if (isExpanded) {
                      {{ reply?.createdAt | date: 'MM/dd/yyyy hh:mm' }}
                    } @else {
                      {{ reply?.createdAt | date: 'MM/dd/yyyy' }}
                    }
                  </span>
                </div>
              </div>
            }
          }
          <div *ngxPermissionsOnly="Permissions.NOTE_ADD_APPEND">
            <div class="flex items-start gap-1.5">
              <app-custom-avatar
                [imgId]="userInfo?.avatar || ''"
                [name]="userInfo?.fullName || ''"
                size="2xs"></app-custom-avatar>
              <textarea
                [(ngModel)]="replyNote"
                class="h-20 w-full !shadow-none focus:border-blue-600"
                pInputTextarea
                [autoResize]="true"
                [rows]="4"
                [placeholder]="translatePrefix + 'enter-note' | translate"
                (keydown.enter)="onReply($event)">
              </textarea>
            </div>
          </div>
        </div>
      </div>
    }
  }
</div>
