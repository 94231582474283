import { Component } from '@angular/core';
import { DashboardStatisticComponent } from '@shared/components/dashboard-statistic/dashboard-statistic.component';
import { UNIT_NUMBER } from '@core/enum/dashboard';
import { ChartModule } from 'primeng/chart';
import { DoughnutChartLegendComponent } from '@feature/dashboard/components/doughnut-chart-legend/doughnut-chart-legend.component';
import { ChartOption } from '@core/models/interfaces/dashboard';
import { BarLineChartComponent } from '../bar-line-chart/bar-line-chart.component';
import { ChartData, TooltipItem } from 'chart.js';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-performance-overview',
  standalone: true,
  imports: [
    DashboardStatisticComponent,
    ChartModule,
    DoughnutChartLegendComponent,
    BarLineChartComponent,
    CommonModule
  ],
  templateUrl: './performance-overview.component.html',
  styleUrl: './performance-overview.component.scss'
})
export class PerformanceOverviewComponent {
  readonly UNIT_NUMBER = UNIT_NUMBER;

  performanceConversionLeads = [
    {
      id: 'Conversion leads',
      title: 'Conversion leads',
      amount: 1248,
      dataType: UNIT_NUMBER.NUMBER,
      isAll: false,
      rate: 7.1
    },
    {
      id: 'Total cost',
      title: 'Total cost',
      amount: 22.48,
      dataType: UNIT_NUMBER.CURRENCY,
      isAll: false,
      rate: -12.2
    },
    {
      id: 'Cost per conversion',
      title: 'Cost per conversion',
      amount: 0.63,
      dataType: UNIT_NUMBER.CURRENCY,
      isAll: false,
      rate: 7.1
    }
  ];

  performanceConversionMessages = [
    {
      id: 'Conversion messages',
      title: 'Conversion messages',
      amount: 1248,
      dataType: UNIT_NUMBER.NUMBER,
      isAll: false,
      rate: 7.1
    },
    {
      id: 'Total cost',
      title: 'Total cost',
      amount: 22.48,
      dataType: UNIT_NUMBER.CURRENCY,
      isAll: false,
      rate: -12.2
    },
    {
      id: 'Cost per conversion',
      title: 'Cost per conversion',
      amount: 0.63,
      dataType: UNIT_NUMBER.CURRENCY,
      isAll: false,
      rate: 7.1
    }
  ];

  leadsOptions: ChartOption[] = [
    {
      label: 'Conversion leads',
      data: 5,
      color: '#3B82F6'
    },
    {
      label: 'Cost per lead',
      data: 5,
      color: '#F97316'
    }
  ];

  dataLeads: ChartData = {
    labels: [...Array(30)].map((_, i) => i + 1),
    datasets: [
      {
        type: 'line',
        label: 'Cost per lead',
        borderColor: '#F97316',
        borderWidth: 2,
        fill: false,
        tension: 0.2,
        data: [...Array(30)].map((_, i) => Math.random() * 4),
        pointRadius: 0,
        pointHoverRadius: 5,
        pointHitRadius: 10
      },
      {
        type: 'bar',
        label: 'Conversion leads',
        backgroundColor: '#3B82F6',
        data: [...Array(30)].map((_, i) => Math.random() * 8),
        borderColor: '#3B82F6',
        borderWidth: 2,
        borderRadius: 2,
        barThickness: 20
      }
    ]
  };

  dataConversion: ChartData = {
    labels: [...Array(30)].map((_, i) => i + 1),
    datasets: [
      {
        type: 'line',
        label: 'Cost per lead',
        borderColor: '#F97316',
        borderWidth: 2,
        fill: false,
        tension: 0.2,
        data: [...Array(30)].map((_, i) => Math.random() * 4),
        pointRadius: 0,
        pointHoverRadius: 5,
        pointHitRadius: 10
      },
      {
        type: 'bar',
        label: 'Conversion leads',
        backgroundColor: '#8B5CF6',
        data: [...Array(30)].map((_, i) => Math.random() * 8),
        borderColor: '#8B5CF6',
        borderWidth: 2,
        borderRadius: 2,
        barThickness: 20
      }
    ]
  };

  //Amount spent
  dataAmountSpent: ChartData = {
    labels: [...Array(10)].map((_, i) => (i + 1) * 3),
    datasets: [
      {
        label: 'Cost per lead',
        borderColor: '#3B82F6',
        borderWidth: 2,
        fill: false,
        tension: 0.05,
        data: [...Array(10)].map((_, i) => Math.random() * 40),
        pointRadius: 0,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        backgroundColor: '#3B82F6',
        borderRadius: 2
      }
    ]
  };

  conversionValueDoughnut: ChartData = {
    labels: ['Message Conversion Value', 'Lead Form Conversion Value', 'Website Conversion Value'],
    datasets: [
      {
        data: [300, 50, 100],
        backgroundColor: ['#BBF7D0', '#16A34A', '#4ADE80'],
        hoverBackgroundColor: ['#BBF7D0', '#16A34A', '#4ADE80']
      }
    ]
  };

  // Message
  conversionOption: ChartOption[] = [
    {
      label: 'Message conversation started',
      data: 5,
      color: '#8B5CF6'
    },
    {
      label: 'Cost per message',
      data: 5,
      color: '#F97316'
    }
  ];

  doughnutOption: ChartOption[] = [
    {
      label: 'Message Conversion Value',
      data: 5,
      color: '#BBF7D0'
    },
    {
      label: 'Lead Form Conversion Value',
      data: 5,
      color: '#16A34A'
    },
    {
      label: 'Website Conversion Value',
      data: 5,
      color: '#4ADE80'
    }
  ];

  optionsLead = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14 // Set font size
          }
        },
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          callback: function (value: number) {
            return `$${value}.00`;
          },
          stepSize: 2,
          font: {
            size: 14 // Set font size
          }
        },
        grid: {
          display: true, // Enable/disable grid lines
          lineWidth: 0.3, // Set the width of the grid lines
          drawTicks: false
        },
        border: {
          display: false
        }
      }
    }
  };

  optionsConversion = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 14 // Set font size
          }
        },
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          callback: function (value: number) {
            return `$${value}.00`;
          },
          stepSize: 2,
          font: {
            size: 14 // Set font size
          }
        },
        grid: {
          lineWidth: 0.3, // Set the width of the grid lines

          drawTicks: false
        },
        border: {
          display: false
        }
      }
    }
  };

  readonly doughnutChartOptions = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: TooltipItem<'doughnut'>) {
            const total = tooltipItem?.dataset?.data?.reduce((a: number, b: number) => a + b, 0) || 0;
            return `${tooltipItem?.formattedValue} (${(((tooltipItem?.parsed || 0) / total) * 100).toFixed(2)}%)`;
          }
        }
      }
    }
  };
}
