<div class="flex flex-col w-full border rounded-md h-[calc(100vh_-_90px)] sticky top-0 overflow-y-auto bg-white">
  <div class="flex flex-col justify-center gap-1.5 border-b px-5 py-4 h-21">
    <div class="text-4 font-semibold">Message flow</div>
    <div>
      Paste JSON code for your message flow. If you make updates on the partner app, you will need to paste the JSON
      code and save your ad again.
    </div>
  </div>
  <div class="flex flex-col gap-4 p-5">
    @if (isShowMessageFlow) {
      <div class="w-1/2">
        <p-floatLabel>
          <input id="title" type="text" class="text-4 font-semibold" pInputText [(ngModel)]="messageFlowName" />
          <label for="title"> Message flow name <span class="text-red-500 ml-0.5">*</span></label>
        </p-floatLabel>
      </div>

      <div class="grid grid-cols-2">
        <div class="w-full bg-white border">
          <p-floatLabel>
            <textarea
              rows="30"
              cols="30"
              pInputTextarea
              [autoResize]="true"
              class="custom-textarea"
              [ngModel]="formattedJson"
              (ngModelChange)="onChangeContent($event)">
            </textarea>
            <label>JSON code</label>
          </p-floatLabel>
        </div>

        <div class="flex items-start justify-center bg-neutral-200 border">
          <div
            class="relative flex flex-col w-[338px] h-[550px] bg-neutral-500 my-10 rounded-tl rounded-tr rounded-bl-lg rounded-br-lg">
            <div class="h-full mt-6 bg-white rounded-lg">
              <div class="py-3 px-5">
                <div class="flex items-center justify-between">
                  <div class="flex items-center gap-2">
                    <p-avatar
                      image="/assets/images/fanpageAvatar.jpg"
                      styleClass="w-11 h-11 overflow-hidden rounded-full border"
                      shape="circle"></p-avatar>
                    <div class="flex flex-col gap-1">
                      <span class="font-semibold">Thinksmart Insurance Kaizen</span>
                      <span class="text-2 text-neutral-600">Typically replies within an hour</span>
                    </div>
                  </div>
                  <i class="icon-wl-phone cursor-pointer text-7 text-blue-400 font-semibold"></i>
                </div>
              </div>
            </div>
            <div class="absolute top-[270px] left-11 flex flex-col items-center justify-center">
              <span class="text-neutral-600 text-1">You open this conversation through an ad. When </span>
              <span class="text-neutral-600 text-1">you reply Thinksmart Insurance Kaizen will be able to</span>
              <span class="text-neutral-600 text-1"> see your public info and which ad you clicked.</span>
            </div>
            <div class="absolute top-[340px] left-3">
              <div class="flex items-center gap-2">
                <div class="flex self-end">
                  <p-avatar
                    image="/assets/images/fanpageAvatar.jpg"
                    styleClass="w-9 h-9 overflow-hidden rounded-full align-self-end border"
                    shape="circle"></p-avatar>
                </div>

                <div
                  class="flex flex-col gap-3 bg-neutral-200 max-w-[224px] p-2 rounded-bl-xs rounded-tl-xl rounded-tr-md rounded-br-md">
                  <div class="text-2 font-medium">{{ text }}</div>
                  <div class="flex flex-col justify-center items-center gap-5 pb-3">
                    @for (button of buttons; let i = $index; track button) {
                      <div class="flex items-center justify-center w-[180px] h-6 font-semibold bg-white rounded-md">
                        {{ button?.title }}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div
              class="absolute top-[355px] left-1/2 transform -translate-x-1/2 justify-center p-2 font-semibold text-blue-400 shadow-lg rounded-full w-fit text-nowrap"></div>
            <div
              class="absolute top-[395px] left-1/2 transform -translate-x-1/2 justify-center p-2 font-semibold text-blue-400 shadow-lg rounded-full w-fit text-nowrap"></div>
            <div
              class="absolute top-[435px] left-1/2 transform -translate-x-1/2 justify-center p-2 font-semibold text-blue-400 shadow-lg rounded-full w-fit text-nowrap"></div>
            <div class="absolute bottom-3 left-3 flex gap-3 w-full">
              <i class="icon-wl-camera cursor-pointer text-7 text-blue-400 font-semibold"></i>
              <i class="icon-wl-picture cursor-pointer text-7 text-blue-400 font-semibold"></i>
              <i class="icon-wl-microphone cursor-pointer text-7 text-blue-400 font-semibold"></i>
              <div class="flex justify-between items-center rounded-full bg-gray-200 w-[176px]">
                <span class="text-neutral-500 pl-3">Aa</span>
                <i class="pr-3 icon-wl-emoji-smile cursor-pointer text-7 text-blue-400 font-semibold"></i>
              </div>
              <i class="icon-ws-thumbs-up cursor-pointer text-7 text-blue-400 font-semibold"></i>
            </div>
          </div>
        </div>
      </div>
    } @else if (isShowNotMessageFlow) {
      <div class="relative first-letter:flex flex-col gap-4 justify-center items-center]">
        <div class="flex justify-center items-center flex-col gap-4 absolute top-14 left-1/2 -translate-x-1/2">
          <div class="flex justify-center items-center h-11 w-11 bg-neutral-alpha-200A rounded-full">
            <i class="icon-wl-message-square-text text-7"></i>
          </div>
          <span class="text-4 font-semibold">No message flow to display</span>
          <div>
            <app-button
              size="sm"
              icon="icon-wl-plus-circle"
              label="Create"
              iconPosition="left"
              variant="primary"
              styleClass=" font-medium"
              (click)="onCreateMessageFlow()"></app-button>
          </div>
        </div>
      </div>
    }
  </div>
  @if (isShowMessageFlow) {
    <div class="flex flex-1 h-19 items-center border-t px-5 py-4 gap-3 justify-end">
      <app-button
        size="sm"
        label="Save"
        variant="primary"
        styleClass="!h-9 w-[65px] font-medium"
        (click)="onUpdateMessageFlow()"></app-button>
    </div>
  }
</div>
