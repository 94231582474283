@if (totalRecords > 5) {
  <div class="flex items-center gap-2 bg-white justify-between h-8">
    <div class="flex items-center gap-2">
      <span>{{ 'common.items-per-page' | translate }}</span>
      <p-dropdown
        [(ngModel)]="pagination.size"
        (onChange)="onSizeChange(pagination.size)"
        [options]="options"
        appendTo="body"
        dropdownIcon="icon-wl-arrow-drop-down ml-5 !text-neutral-600"></p-dropdown>
    </div>

    <!-- Paginator -->
    <div class="flex items-center gap-1">
      <button
        (click)="pagination.page > 0 ? onPageChange(pagination.page - 1) : null"
        [ngClass]="pagination.page > 0 ? '' : 'opacity-50'"
        class="h-8 min-w-8 flex items-center justify-center border rounded-sm">
        <i class="icon-wl-chevron-left text-6"></i>
      </button>

      <button
        *ngFor="let page of pageNumbers"
        [ngClass]="page === pagination.page ? 'bg-neutral-alpha-200A' : 'border'"
        (click)="onPageChange(page)"
        class="p-1.5 max-h-8 min-w-8 flex items-center justify-center rounded-sm font-medium">
        @if (page >= 0) {
          {{ page + 1 }}
        } @else {
          ...
        }
      </button>

      <button
        (click)="pagination.page < totalPages - 1 ? onPageChange(pagination.page + 1) : null"
        [ngClass]="pagination.page < totalPages - 1 ? '' : 'opacity-50'"
        class="h-8 min-w-8 flex items-center justify-center rounded-sm border">
        <i class="icon-wl-chevron-right text-6"></i>
      </button>
    </div>

    <div class="flex items-center gap-2">
      <span>{{ 'common.jump-to' | translate }}</span>
      <input type="text" pInputText class="h-8 w-12 text-center" [(ngModel)]="jumpTo" (keyup.enter)="onJumpPage()" />
    </div>
  </div>
}
