<app-button
  [isDropdown]="true"
  icon="icon-wl-upload"
  size="md"
  label="Export"
  iconPosition="left"
  variant="outline-secondary"
  (onClick)="isVisible = !isVisible"></app-button>

<p-dialog
  [focusOnShow]="false"
  [draggable]="false"
  header="Export report"
  [modal]="true"
  [(visible)]="isVisible"
  [style]="{
    width: '1080px'
  }">
  <div class="flex flex-col gap-4">
    <div class="flex items-center gap-1">
      <span class="font-semibold">Last month:</span>
      <span>12/01/2024 - 12/31/2024</span>
    </div>

    <app-form-control [control]="exportName" label="Export name" [floatLabel]="true">
      <input input [formControl]="exportName" pInputText type="text" />
    </app-form-control>

    <div class="border rounded-sm flex">
      <!-- Choose column -->
      <div class="flex-[2] px-5 py-4 h-overflow">
        <h3 class="font-semibold">Choose column</h3>
        <div class="mt-4 mb-2">
          <app-search-input
            inputClass="h-8 w-full"
            placeholder="Search for metric or column settings"></app-search-input>
        </div>

        @for (column of groupCols; track column.code; let last = $last) {
          <div [id]="'PARENT_' + column.code">
            <div class="font-semibold pt-2 pb-1">{{ column.name }}</div>
            <div>
              @for (child of column.children; track $index) {
                <div [id]="'CHILD_' + child.code">
                  <div class="flex items-center justify-between">
                    <span class="text-2 text-neutral-600 uppercase pt-2 pb-0.5">{{ child.name }}</span>
                    <div
                      class="h-5 w-5 cursor-pointer hover:bg-gray-100 rounded flex items-center justify-center"
                      (click)="child.expand = !child.expand">
                      <i
                        class="text-6"
                        [ngClass]="child.expand ? 'icon-wl-arrow-drop-down' : 'icon-wl-arrow-drop-up'"></i>
                    </div>
                  </div>
                  @if (child.expand) {
                    @for (col of child.children; track $index) {
                      <div class="flex items-center h-8 gap-2">
                        <p-checkbox [(ngModel)]="columnsSelected" [value]="col.code" />
                        <span>{{ col.name }}</span>
                      </div>
                    }
                  }
                </div>
              }
            </div>
          </div>

          @if (!last) {
            <p-divider />
          }
        }
      </div>

      <!-- Default columns -->
      <div class="flex-1 px-5 py-4 h-overflow bg-neutral-200">
        <h3 class="font-semibold mb-1.5">Default columns</h3>

        <!-- Columns -->
        <div cdkDropList (cdkDropListDropped)="onDropColumns($event)">
          @for (column of columnsSelected; track column) {
            <div cdkDrag class="customize-column flex items-center justify-between gap-2 py-1 rounded">
              <div class="flex items-center gap-2">
                <div class="w-5 h-full flex items-center justify-center cursor-grab" cdkDragHandle>
                  <div class="w-4 h-4 flex items-center justify-center">
                    <i class="icon-wl-drag-indicator text-4 text-neutral-alpha-500A"></i>
                  </div>
                </div>
                <span>{{ objectColumn[column] }}</span>
              </div>

              <div
                class="min-w-7 h-7 flex items-center justify-center rounded-sm cursor-pointer hover:bg-neutral-200"
                (click)="onRemoveSelectedColumn(column)">
                <i class="icon-wl-close text-4"></i>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center justify-end gap-1">
      <app-button
        [label]="'action.cancel' | translate"
        variant="outline-secondary"
        size="xl"
        (onClick)="isVisible = false"></app-button>
      <app-button
        [label]="'action.export' | translate"
        size="xl"
        [loading]="isEditing"
        (onClick)="onExport()"></app-button>
    </div>
  </ng-template>
</p-dialog>
