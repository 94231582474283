import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';

export interface Pagination {
  size: number;
  page: number;
}

@Component({
  selector: 'app-custom-paginator',
  standalone: true,
  imports: [CommonModule, DropdownModule, PaginatorModule, InputTextModule, TranslateModule],
  templateUrl: './custom-paginator.component.html',
  styleUrl: './custom-paginator.component.scss'
})
export class CustomPaginatorComponent {
  @Input() pagination: Pagination = { page: 0, size: 10 };
  @Input() totalRecords = 0;
  @Input() options: number[] = [5, 10, 20];

  @Output() paginationChange = new EventEmitter<Pagination>();
  @Output() onChange = new EventEmitter();

  jumpTo = 1;

  onJumpPage() {
    if (this.jumpTo <= 0) {
      this.jumpTo = 1;
    }
    const maxPage = Math.ceil(this.totalRecords / this.pagination.size);

    if (this.jumpTo <= maxPage) {
      this.pagination.page = this.jumpTo - 1;
    } else {
      this.jumpTo = maxPage;
      this.pagination.page = maxPage - 1;
    }

    this.paginationChange.emit(this.pagination);
    this.onChange.emit();
  }

  get totalPages(): number {
    return Math.ceil(this.totalRecords / this.pagination.size);
  }

  get pageNumbers(): number[] {
    const totalPages = this.totalPages;
    const currentPage = this.pagination.page;

    if (totalPages <= 6) {
      return Array.from({ length: totalPages }, (_, i) => i);
    }

    let pages: number[] = [];
    if (currentPage <= 2) {
      pages = [0, 1, 2, 3, 4, 5];
    } else if (currentPage >= totalPages - 3) {
      pages = [totalPages - 6, totalPages - 5, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1];
    } else {
      pages = [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
    }

    if (currentPage > 2) {
      pages = [0, -1, ...pages];
    }
    if (currentPage < totalPages - 3) {
      pages = [...pages, -1, totalPages - 1];
    }

    return pages;
  }

  onPageChange(page: number): void {
    if (page === -1) return;
    if (page !== this.pagination.page) {
      this.pagination.page = page;
      this.paginationChange.emit(this.pagination);
      this.onChange.emit();
    }
  }

  onSizeChange(size: number): void {
    this.pagination.size = size;
    this.pagination.page = 0;
    this.paginationChange.emit(this.pagination);
    this.onChange.emit();
  }
}
