import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationItem } from '@core/models/interfaces/system';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { SidebarModule } from 'primeng/sidebar';

import { FormsModule } from '@angular/forms';
import { SIDEBAR } from '@core/constants/sidebar';
import { IntegrationItem } from '@core/models/interfaces/facebook/integration';
import { FacebookAdsAdminService } from '@core/services/facebook.service.ts/facebook-ads-admin.service';
import { AppState } from '@state/app.state';
import { setBusinessIdSelected } from '@state/auth/auth.actions';
import { selectBusinessIdSelected, selectFacebookAccountSelected } from '@state/auth/auth.selectors';
import { setIsSidebar } from '@state/system/system.actions';
import { selectIsSidebar } from '@state/system/system.selectors';
import { DropdownModule } from 'primeng/dropdown';
import { SkeletonModule } from 'primeng/skeleton';
import { Subject, takeUntil } from 'rxjs';
import { APP_PERMISSION } from '@core/enum/permisson';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SidebarModule,
    RouterModule,
    TranslateModule,
    NgxPermissionsModule,
    DropdownModule,
    SkeletonModule
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit, OnDestroy {
  readonly navigationItems = SIDEBAR;
  readonly unsubscribe$ = new Subject();
  readonly env = environment;

  businesses: IntegrationItem[] = [];
  isLoadingBusiness: boolean = false;
  businessIdSelected = '';
  isMenuShow = true;
  showChildren: boolean[] = [];
  isSidebar$ = this.store.select(selectIsSidebar);

  constructor(
    private store: Store<AppState>,
    private facebookAdsAdminService: FacebookAdsAdminService,
    private ngxPermissionsService: NgxPermissionsService
  ) {}

  ngOnInit() {
    this.showChildren = this.navigationItems.map(item => !!item.children);
    this.subscribeFacebookAccountSelected();
    this.subscribeBusinessIdSelected();
  }

  subscribeFacebookAccountSelected() {
    this.store
      .select(selectFacebookAccountSelected)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(account => {
        if (account) {
          this.fetchBusiness(account.accessToken);
          this.ngxPermissionsService.addPermission(APP_PERMISSION.FACEBOOK_LOGGED_IN);
        }
      });
  }

  subscribeBusinessIdSelected() {
    this.store
      .select(selectBusinessIdSelected)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(businessIdSelected => {
        this.businessIdSelected = businessIdSelected;
      });
  }

  fetchBusiness(accessToken?: string) {
    if (!accessToken) return;
    this.isLoadingBusiness = true;
    this.facebookAdsAdminService
      .getBusinessPortfolio(accessToken)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          const data = res.data;
          if (data?.length) {
            this.businesses = data;
            this.store.dispatch(setBusinessIdSelected({ id: data[0].id }));
          } else {
            this.businesses = [];
            this.store.dispatch(setBusinessIdSelected({ id: '' }));
          }

          this.isLoadingBusiness = false;
        },
        error: () => {
          this.isLoadingBusiness = false;
        }
      });
  }

  onChangeBusiness() {
    this.store.dispatch(setBusinessIdSelected({ id: this.businessIdSelected }));
  }

  setIsSidebar(isSidebar: boolean) {
    this.store.dispatch(setIsSidebar({ isSidebar }));
  }

  onClickItem(item: NavigationItem, index: number) {
    if (item.children) {
      this.showChildren[index] = !this.showChildren[index];
    } else {
      this.setIsSidebar(false);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
