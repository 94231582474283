import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { CreatePartnerApps, PartnerAppDetails } from '@core/models/interfaces/facebook/partner-apps';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FacebookPartnerAppService {
  readonly baseURL: string = `${environment.API_URL}/crm/facebook/partner-apps`;

  constructor(private httpClient: HttpClient) {}

  createPartnerApp(body: { name: string }) {
    return this.httpClient.post<ResponseApi<CreatePartnerApps>>(`${this.baseURL}`, body);
  }

  getPartnerApp(keyword?: string, page?: number, size?: number) {
    let params = new HttpParams();

    if (keyword) {
      params = params.set('keyword', keyword);
    }
    if (page) {
      params = params.set('page', page);
    }
    if (size) {
      params = params.set('size', size);
    }
    return this.httpClient.get<ResponseListApi<PartnerAppDetails>>(`${this.baseURL}/page`, {
      params
    });
  }

  deletePartnerApp(id: string) {
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }
}
