import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AudienceColumn } from '@core/models/interfaces/columns/columns';
import { ResponseApi } from '@core/models/interfaces/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ColumnService {
  private baseUrl = `${environment.API_URL}/crm/columns`;

  constructor(private httpClient: HttpClient) {}

  getAllColumns() {
    return this.httpClient.get<ResponseApi<AudienceColumn[]>>(`${this.baseUrl}`);
  }
}
