import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageTemplateSample } from '@core/constants/message-flow';
import { FlowDetails, PartnerAppDetails } from '@core/models/interfaces/facebook/partner-apps';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { AvatarModule } from 'primeng/avatar';
import { EditorModule } from 'primeng/editor';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { FacebookMessageFlowService } from '@core/services/facebook.service.ts/message-flow.service';
import { ReceivingApp } from '@core/models/interfaces/facebook/partner-apps';
@Component({
  selector: 'app-message-flow-view',
  standalone: true,
  imports: [
    FloatLabelModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    TranslateModule,
    InputTextModule,
    EditorModule,
    ButtonComponent,
    ReactiveFormsModule,
    ProgressBarModule,
    FloatLabelModule,
    FormsModule,
    InputTextareaModule,
    AvatarModule
  ],
  templateUrl: './message-flow-view.component.html',
  styleUrl: './message-flow-view.component.scss'
})
export class MessageFlowViewComponent implements OnChanges {
  @Input() messageFlowName: string = '';
  @Input() messageFlowActive: FlowDetails;
  @Input() currentPartnerAppActive: PartnerAppDetails | undefined;
  @Input() isShowNotMessageFlow: boolean = false;
  @Input() isShowMessageFlow: boolean = false;

  @Output() messageFlowUpdate = new EventEmitter<{ active: FlowDetails; name: string }>();
  @Output() createMessageFlow = new EventEmitter<string>();

  jsonString: ReceivingApp[] = [];
  text: string = '';
  title: string = '';
  buttons: { title: string }[] = [];

  readonly MessageTemplateSample = MessageTemplateSample;

  constructor(private FacebookMessageFlowService: FacebookMessageFlowService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['messageFlowActive']) {
      this.getMessageFlow();
    }
  }

  getMessageFlow() {
    if (this.messageFlowActive) {
      this.FacebookMessageFlowService.getMessageFlowById(this.messageFlowActive?.id).subscribe({
        next: res => {
          try {
            const originalJson = res.data.json.replace(/'/g, "\\'");
            if (originalJson) {
              this.jsonString = JSON.parse(originalJson);
              this.text = this.jsonString[0]?.message?.attachment?.payload?.text ?? '';
              this.buttons = this.jsonString[0]?.message?.attachment?.payload?.buttons ?? [];
            }
          } catch (error) {
            console.error('Error parsing JSON or accessing properties:', error);
            this.jsonString = [];
            this.text = '';
            this.buttons = [];
          }
        }
      });
    }
  }

  get formattedJson(): string {
    try {
      return JSON.stringify(this.jsonString, null, 2) ?? '';
    } catch (error) {
      console.error('Error formatting JSON:', error);
      return '';
    }
  }

  onChangeContent(event: string) {
    const cleanedContent = event.trim().replace(/,\s*]$/, ']');
    try {
      this.jsonString = JSON.parse(cleanedContent);
      this.text = this.jsonString[0].message?.attachment?.payload.text;
      this.buttons = this.jsonString[0]?.message?.attachment?.payload?.buttons;
    } catch (error) {
      console.error('Error parsing JSON:');
    }
  }

  onCreateMessageFlow() {
    this.createMessageFlow.emit(this.currentPartnerAppActive?.id);
  }

  onUpdateMessageFlow() {
    try {
      this.messageFlowActive.json = JSON.stringify(this.jsonString);

      this.messageFlowUpdate.emit({
        active: this.messageFlowActive,
        name: this.messageFlowName
      });
    } catch (error) {
      console.error('Error updating message flow:', error);
    }
  }
}
