<div class="bg-white px-8 py-5">
  <!-- HEADER -->
  <header class="h-8 flex items-center justify-between">
    <h1 class="text-6 font-semibold">Dashboard</h1>

    <div class="flex items-center gap-4">
      <div class="flex items-center gap-1.5">
        <app-custom-calendar></app-custom-calendar>

        @if (accountBusinesses.length) {
          <app-ad-account-select
            [(adAccountSelected)]="adAccountSelected"
            [accountBusinesses]="accountBusinesses"
            (adAccountSelectedChange)="onChangeAdAccount()"></app-ad-account-select>
        }
      </div>

      <app-export-report-dashboard></app-export-report-dashboard>
    </div>
  </header>

  <!-- REACH & ENGAGEMENT PERFORMANCE -->
  <app-reach-engagement-performance></app-reach-engagement-performance>

  <!-- PERFORMANCE OVERVIEW -->
  <app-performance-overview></app-performance-overview>

  <!-- AUDIENCE AND INSIGHTS -->
  <app-audience-and-insights></app-audience-and-insights>
</div>
