<div>
  <div class="w-[389px] border rounded-md bg-white">
    <div class="flex flex-col gap-3 py-5 pl-5 pr-3">
      <div class="flex justify-between items-start">
        <span class="text-4 font-semibold">All apps</span>
        <div class="flex items-center px-2 h-7 gap-1 hover:cursor-pointer" (click)="this.isCreateApp = true">
          <i class="justify-end icon-wl-plus-circle text-4 text-primary-600"></i>
          <span class="font-medium text-primary-600">Create app</span>
        </div>
      </div>

      <app-search-input
        inputClass="h-8 w-[349px]"
        [debounceTime]="600"
        placeholder="Search by name"
        (onSearch)="onSearch($event)"></app-search-input>
      <div class="h-[calc(100vh_-_216px)] overflow-y-auto">
        @if (!loading) {
          @if (partnerApps.length) {
            @for (partnerApp of partnerApps; track partnerApp.id) {
              <div
                class="group flex items-center justify-between h-10 cursor-pointer rounded-sm pr-1"
                [ngClass]="[
                  partnerApp.id === partnerAppActive
                    ? 'bg-blue-600/10  hover:bg-blue-600/10 text-blue-600'
                    : 'hover:bg-gray-600/10 text-neutral-1000'
                ]">
                <a
                  [routerLink]="[]"
                  [queryParams]="{ partnerAppId: partnerApp.id }"
                  (click)="onClickItem(partnerApp)"
                  class="flex-1 flex items-center">
                  @if (partnerApp.flows) {
                    <div (click)="onExpanded(partnerApp)" class="w-10 h-10 flex items-center justify-center">
                      <i
                        class="icon-wl-arrow-drop-right text-6"
                        [ngClass]="{ 'rotate-90 transition': partnerApp.expanded }"></i>
                    </div>
                  } @else {
                    <div class="w-10 h-10 flex items-center justify-center"></div>
                  }
                  <span class="font-medium">
                    {{ partnerApp.name }}
                  </span>
                </a>

                <div
                  class="group-hover:flex items-center"
                  [ngClass]="partnerApp.id === partnerAppActive || menu.visible ? 'flex' : 'hidden'">
                  <div class="w-9 h-9 flex items-center justify-center" (click)="onCreateMessageFlow(partnerApp.id)">
                    <i class="icon-wl-plus-circle text-6 text-blue-600"></i>
                  </div>
                  <div class="w-9 h-9 flex items-center justify-center" (click)="menu.toggle($event)">
                    <i class="icon-wl-dots-vertical text-6"></i>
                  </div>
                  <p-menu #menu [popup]="true" [model]="actions" appendTo="body">
                    <ng-template pTemplate="item" let-item>
                      <a
                        pRipple
                        [ngClass]="item.styleClass"
                        class="flex items-center h-9 px-2 cursor-pointer"
                        (click)="onAction(item, partnerApp)">
                        <i [class]="item.icon + ' text-6'"></i>
                        <span class="ml-2 text-3">{{ 'action.' + item.label | translate }}</span>
                      </a>
                    </ng-template>
                  </p-menu>
                </div>
              </div>
              @if (partnerApp.expanded) {
                @for (subPartnerApp of partnerApp.flows; track subPartnerApp.id) {
                  <div
                    class="group flex items-center justify-between h-10 cursor-pointer rounded-sm pr-1"
                    [ngClass]="[
                      subPartnerApp.id === partnerAppActive
                        ? 'bg-blue-600/10  hover:bg-blue-600/10 text-blue-600'
                        : 'hover:bg-gray-600/10 text-neutral-1000'
                    ]">
                    <a
                      [routerLink]="[]"
                      [queryParams]="{ partnerAppId: subPartnerApp.id }"
                      class="flex-1 flex items-center"
                      (click)="onClickSubItem(subPartnerApp)">
                      <div class="border-r border-dashed w-5 h-10"></div>

                      <span class="font-medium ml-8.5">
                        {{ subPartnerApp.name }}
                      </span>
                    </a>

                    <div
                      class="group-hover:flex items-center"
                      [ngClass]="subPartnerApp.id === partnerAppActive ? 'flex' : 'hidden'">
                      <div
                        class="w-9 h-9 flex items-center justify-center"
                        (click)="onDeleteMessageFlow(subPartnerApp.id)">
                        <i class="icon-wl-delete text-6 text-red-600"></i>
                      </div>
                    </div>
                  </div>
                }
              }
            }
          } @else {
            <app-empty
              size="xs"
              title="No partnerApp available"
              titleClass="!text-4 font-medium"
              styleClass="!gap-3"></app-empty>
          }
        } @else {
          <div class="h-full flex items-center justify-center">
            <app-custom-loading></app-custom-loading>
          </div>
        }

        <!-- @if (totalpartnerApps !== partnerApps.length) {
          <div class="w-full py-4 pr-5 flex justify-end items-center">
            <span class="text-3 text-primary-500 hover:cursor-pointer hover:font-medium" (click)="fetchAllpartnerApp()">{{
              'action.view-all' | translate
            }}</span>
          </div>
        } -->
      </div>
    </div>
  </div>
</div>
<p-dialog header="Create partner app" [modal]="true" [(visible)]="isCreateApp" [style]="{ width: '480px' }">
  <div class="flex flex-1 items-center gap-4 mb-8">
    <p-floatLabel>
      <input id="title" type="text" class="text-4 font-semibold" pInputText [(ngModel)]="partnerAppName" />
      <label for="title"> Partner app name </label>
    </p-floatLabel>
  </div>
  <ng-template pTemplate="footer">
    <app-button label="Cancel" variant="outline-secondary" size="xl" (click)="isCreateApp = false"></app-button>
    <app-button label="Create" size="xl" (click)="onCreatePartnerApps()"></app-button>
  </ng-template>
</p-dialog>
