<!-- TITLE -->
<!-- <div class="flex items-center gap-2 pl-6">
    <span class="text-lg font-semibold">{{
      translatePrefix + "heatmap" | translate
    }}</span>
    <i
      class="sctr-icon-info-circle"
      [pTooltip]="translatePrefix + 'tooltip.heatmap' | translate"
    ></i>
  </div> -->
@if (data?.length) {
  <ng-container>
    <!-- CAPTION -->

    <!-- CHART -->
    <div id="chart" class="-mt-7 pr-[2px] pt-5">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [dataLabels]="chartOptions.dataLabels"
        [title]="chartOptions.title"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [plotOptions]="chartOptions.plotOptions"
        [legend]="{ show: false }"></apx-chart>
    </div>
    <div>
      <div class="flex items-center justify-center">
        @for (hour of HOURS_CONFIG; track $index) {
          <div class="flex-1 text-center text-sm font-medium h-5">
            {{ hour }}
          </div>
        }
      </div>
    </div>

    <!-- NOTE -->
    <!-- <div class="pl-[110px] pr-6 mt-4">
        <div class="flex gap-2">
          @for (color of COLOR_CONFIG; track $index) {
            <div
            [style.backgroundColor]="color"
            class="h-4 flex-1 rounded-sm"
          ></div>
          }
          
        </div>
        <div class="text-sm flex justify-between mt-2">
          <span>{{ translatePrefix + "lowest-engagement" | translate }}</span>
          <span>{{ translatePrefix + "highest-engagement" | translate }}</span>
        </div>
      </div> -->
  </ng-container>
}

<!-- <div
    *ngIf="!data.length"
    class="h-full w-full flex items-center justify-center"
  >
    <app-table-empty></app-table-empty>
  </div> -->
<!-- </div> -->
